import fullstack from "./img/Group 175.svg";
import userexperience from "./img/Group 176.svg";
import hr from "./img/Group 177.svg";
import react from "./img/Group 178.svg";
import location from "./img/location.svg";
import Frame from "./img/Frame.svg";
import { Link } from "react-router-dom";
// import AnimationCareerPage from "../Home/Animation";

import { motion } from "framer-motion";
import FadeInAnimationMultiple from "../../layout/FadeInAnimationMultiple";

export default function JobCards() {
  const jobData = [
    {
      title: "Full Stack Developer",
      location: "Chennai",
      type: "Internship",
      image: fullstack,
      link: "/career/fullStack",
    },
    {
      title: "UI/UX Developer - Intern",
      location: "Chennai",
      type: "Internship",
      image: userexperience,
      link: "/career/uiux",
    },
    {
      title: "HR - Intern",
      location: "Chennai",
      type: "Internship",
      image: hr,
      link: "/career/hr",
    },
    {
      title: "React Native Developer - Intern",
      location: "Chennai",
      type: "Internship",
      image: react,
      link: "/career/reactnative",
    },
  ];

  const titleStyle = {
    background:
      "var(--gradient, linear-gradient(90deg, #EC568E 0%, #3110B5 100%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    display: "inline-block",
  };

  return (
    <div className="grid md:grid-rows-2 md:grid-flow-col items-center justify-center gap-y-12 px-4 md:px-0 lg:gap-x-[15rem] lg:gap-y-[5rem] py-8 md:py-10 overflow-hidden">
      {jobData.map((job, index) => (
        <motion.div
          className="flex flex-col w-full md:w-[34.375rem] lg:w-[25rem] rounded-lg justify-between gap-1 lg:gap-6 h-auto shadow-2xl"
          key={index}
          variants={FadeInAnimationMultiple}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          custom={index}
        >
          <div className="w-full  h-[40%]  grid justify-items-stretch">
            <img src={job.image} alt="/" className="justify-self-center" />
          </div>
          <div className="mt-1 h-[60%] ">
            <h2 className="font-semibold px-2 pl-4 text-xl md:text-2xl lg:text-2xl ">
              <span style={titleStyle}>{job.title}</span>
            </h2>
          </div>
          <div className="flex md:flex-row justify-between items-center py-5 px-2  ">
            <div className="flex flex-wrap gap-2 pl-2">
              <img
                src={location}
                className="w-4 h-4 md:w-6 md:h-6 mt-1"
                alt="/"
              />
              <span className="font-semibold text-md md:text-xl lg:text-2xl  text-blue-700 ">
                {job.location}
              </span>
            </div>
            <div className="flex flex-wrap justify-between gap-2">
              <img src={Frame} className="w-4 h-4 md:w-6 md:h-6 mt-1" alt="/" />
              <span className="font-semibold text-md md:text-xl lg:text-2xl pr-5 text-pink-500">
                {job.type}
              </span>
            </div>
          </div>

          <div
            className="w-full flex justify-end"
          >
            <Link
              to={job.link}
              className="flex text-xl md:text-2xl lg:text-xl justify-end items-center w-fit  py-2 pb-8  px-5 mr-3 hover:text-blue-800 font-bold text-blue-600"
            >
              Apply &gt;&gt;
            </Link>
          </div>
        </motion.div>
      ))}
    </div>
  );
}
