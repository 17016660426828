import React from "react";
import onlinesurvey from "./img/online-survey 2.svg";
import training from "./img/presentation 1.svg";
import events from "./img/event-management 1.svg";
import qualityassurance from "./img/testing 1.svg";
import noticeboard from "./img/noticeboard 1.svg";
import feedback from "./img/feedback 4.svg";
import education from "./img/teamwork 1.svg";
import suitcase from "./img/suitcase 1.svg";
import project from "./img/accountability 1.svg";
import FeatureCard from "./FeatureCard";

const PlacementData = [
  {
    title: "Online Assessment",
    content:
      "Experience the future of talent evaluation with our PMS's Online Assessment feature. Conduct customized tests and coding challenges, enabling recruiters to identify talent efficiently and effectively.",
    image: onlinesurvey,
  },
  {
    title: "Student Management",
    content:
      "Elevate student profiles, highlighting achievements and skills. Explore jobs, internships, and enhance prospects with direct applications, status tracking, and resume support through our powerful platform.",
    image: training,
  },
  {
    title: "Event Management",
    content:
      "Event Scheduling in our Placement Management System (PMS) – your path to organized placements! Stay updated, plan attendance, secure interview slots, and elevate your journey with our advanced PMS",
    image: events,
  },
  {
    title: "Programming Assessment",
    content:
      " Revolutionize talent assessment with our PMS's interactive coding challenges. Efficiently evaluate candidates, elevate placements, and empower organizations to identify skilled programmers.",
    image: qualityassurance,
  },
  {
    title: "Notice Management",
    content:
      "Elevate your journey with our PMS Notice Management. Stay informed about placements, deadlines, and events for a successful career path, fostering effective communication and transparency.",
    image: noticeboard,
  },
  {
    title: "Feedback Management",
    content:
      "In our PMS, Feedback Management fuels growth. Students, recruiters, and administrators collaborate, driving data-driven enhancements. Shape the PMS future with us, unlocking limitless potential.",
    image: feedback,
  },
  {
    title: "On Campus Management ",
    content:
      "Elevate Your Campus Placements: Streamline event scheduling, student registration, company insights, and recruiter interactions with our PMS. Join us in shaping successful futures.",
    image: education,
  },
  {
    title: "Off Campus Management",
    content:
      "Nationwide student registration, profile access, and applications through our PMS. Admins oversee logistics and deadlines. Recruiters gain access to a broader talent pool. Empower student dreams with us.",
    image: suitcase,
  },
  {
    title: "Report Management",
    content:
      "Empower data-driven placement decisions with our PMS. Monitor progress, customize reports, and revolutionize placements through insightful data on application status and interview outcomes.",
    image: project,
  },
];

export default function Placement() {
  return <FeatureCard arr={PlacementData} height="  lg:h-[25rem] h-[22rem] " />;
}
