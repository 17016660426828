export default function HomeCarousel({ header, paragraph }) {
  return (
    <div className="flex flex-col justify-evenly md:gap-6 pb-8 md:py-16 text-center !bg-[#131B291A] h-80">
      <h3 className="font-bold text-xl md:text-[2.5rem] font-poppins">
        {header}
      </h3>
      <p className="font-semibold text-xs md:text-xl text-justify     text-[#131B2980] px-6 md:w-4/5 mx-auto break-words leading-5 font-inter">
        {paragraph}
      </p>
    </div>
  );
}
