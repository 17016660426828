import React from "react";

export default function OtherHeading(props) {
  const titleStyle = {
    background:
      "var(--gradient, linear-gradient(90deg, #EC568E 0%, #3110B5 100%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    display: "inline-block",
    textDecoration: "underline", // Add underline style
    textDecorationColor: "transparent", // Make underline transparent
  };

  return (
    <div id="services" className="flex items-center justify-center">
      <div className="py-[1.25rem]   lg:py-[3rem] lg:w-5/6  w-full    flex flex-col gap-3 justify-between items-center  ">
        <p className="text-2xl font-bold md:text-3xl lg:text-5xl ">
          <h1 className="underline underline-offset-2" style={titleStyle}>
            {props.heading}
          </h1>
        </p>
        <p className="w-full px-2 pt-1 text-sm text-center text-gray-600 lg:text-lg lg:w-5/6">
          {props.para}
        </p>
      </div>
    </div>
  );
}
