import React, { useState, useEffect } from 'react';
import card1 from "./assets/card1.svg";
import card2 from "./assets/card2.svg";
import card3 from "./assets/card3.svg";
import card4 from "./assets/card4.svg";
import { motion } from "framer-motion";

export default function Animation() {
  const [currentCard, setCurrentCard] = useState(0);
  const cards = [card1, card2, card3, card4];

  // Define animation properties
  const animationProps = {
    initial: { y: -100, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    exit: { y: 100, opacity: 0 },
    transition: {
      y: { type: "spring", stiffness: 100, damping: 10 },
     duration: 4  
    },
  };

  useEffect(() => {
    // To sync with animation, make sure to change the card after the animation ends (every 2.5 seconds)
    const interval = setInterval(() => {
      setCurrentCard((prevCard) => (prevCard + 1) % cards.length);
    }, 4000); // Change card every 2.5 seconds to give 0.5 second of idle time after animation

    return () => clearInterval(interval); // Cleanup
  }, []);

  return (
    <div className="flex items-center justify-center w-full mx-auto">
      <div className="flex justify-center items-center bg-white my-14 h-auto lg:py-0 py-1 lg:h-[50vh] w-[90%] bg-gradient-to-r from-[#EC568E] to-[#3110B5] rounded-md">
        <div className="flex justify-center items-center md:gap-8 bg-white rounded-md h-[97%] w-[97%] md:w-[99.5%] md:h-[98%] py-4">
          <motion.img {...animationProps} src={cards[currentCard]} alt={`icon${currentCard}`} className="h-full w-[100%]" />
        </div>
      </div>
    </div>
  );
}
