import React from 'react'
import ContentForCareer from '../ContentForCareer';
import StipendStructure from '../StipendStructure';
import TechnicalRequirements from '../TechnicalRequirements';


export default function FullStackJob() {
    const JobRequirements = [
      "Developing and maintaining both front-end and back-end components of web applications.",
  "Designing user interactions on web pages and ensuring responsiveness.",
  "Creating server-side APIs and integrating with various databases and data storage solutions.",
  "Collaborating with UI/UX designers to translate wireframes and designs into functional web pages.",
  "Implementing security protocols and data protection measures.",
  "Optimizing application performance for speed and scalability.",
  "Debugging and troubleshooting issues across the entire stack.",
  "Collaborating with cross-functional teams to define and deliver project requirements.",
  "Staying up-to-date with emerging technologies and trends in web development.",
  "Participating in code reviews, testing, and deployment processes.",
  "Developing and maintaining both front-end and back-end components of web applications.",
  "Designing user interactions on web pages and ensuring responsiveness.",
  "Creating server-side APIs and integrating with various databases and data storage solutions.",
  "Collaborating with UI/UX designers to translate wireframes and designs into functional web pages.",
  "Implementing security protocols and data protection measures.",
  "Optimizing application performance for speed and scalability.",
  "Debugging and troubleshooting issues across the entire stack.",
  "Collaborating with cross-functional teams to define and deliver project requirements.",
  "Staying up-to-date with emerging technologies and trends in web development.",
  "Participating in code reviews, testing, and deployment processes."

      ];
      const jobResponsibilities = [
        "Front-End Mastery: Skilled in front-end technologies like HTML, CSS, and JavaScript (including ReactJS and AngularJS).",
        "Back-End Proficiency: Acquainted with back-end development languages and frameworks, such as Node.js and Django.",
        "Database Dexterity: Familiarity with databases and their management, including SQL and MongoDB.",
        "Collaborative Coding: Knowledge of version control systems like Git and collaborative development approaches.",
        "Problem-Solving Passion: Strong problem-solving skills and a keen eagerness to learn and adapt to emerging technologies."
      ];
      const skillsArray = [
        "Portfolio: A strong portfolio showcasing past projects, code samples, and contributions to demonstrate your capabilities.",
        "Communication: Strong communication skills to collaborate effectively with team members, clients, and stakeholders.",
        "Problem-Solving: Ability to analyze complex issues, debug problems, and devise effective solutions.",
        "Teamwork: Capability to work well within a team environment, actively participating in discussions and contributing positively.",
        "Time Management: Effective time management skills to meet project deadlines and deliver high-quality work.",
        "Attention to Detail: Keen attention to detail in coding, testing, and documentation to ensure software quality.",
        "Adaptability: Ability to adapt to changing project requirements, technologies, and client needs.",
        "Client-Focused Mindset: Understanding of client objectives and the ability to align technical solutions with business goals.",
        "Continuous Learning: Eagerness to engage in continuous learning, keeping up-to-date with industry best practices."
      ];
      const PrimarySkills = [
        "For the Frontend : React Js",
        "For the Backend : Node Js",
        "For the Data base : SQL",
        "For the Soft skill : Communication, Adaptation.",
      ]
  return (
    <>
    <ContentForCareer
      heading={"Responsibilities"}
      ArrayForContent={JobRequirements}
    />
    <ContentForCareer
      heading={"Requirement"}
      ArrayForContent={jobResponsibilities}
    />
    <ContentForCareer
      heading={"Additional Requirements"}
      ArrayForContent={skillsArray}
    />
    <StipendStructure />
    <TechnicalRequirements
      PrimarySkills={PrimarySkills}
    /></>
  )
}
