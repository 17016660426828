import React from "react";
import ellipse from "./img/Group 171.svg";
import ellipsee from "./img/Group 173.svg";

import { motion } from "framer-motion";
import FadeInAnimation from "../../layout/FadeInAnimation";

export default function Vision() {
  return (
    <div className="flex   flex-wrap flex-row gap-[30px] justify-around  items-center w-full px-4 lg:px-0">
      <motion.div className="flex flex-col text-center w-[90%] shadow-md  p-4 rounded-lg  lg:w-[450px] lg:h-auto "
        variants={FadeInAnimation}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >

        <div className="flex items-center justify-center w-full h-full pt-10 pb-4 ">

          <img
            className="w-32 h-32 "
            src={ellipse}
            alt="Ellipse"
          />
        </div>
        <div className="py-2 text-xl font-bold lg:text-4xl">
          Vision
          <blockquote>
          <p className="px-2 py-2 text-sm subpixel-antialiased font-normal text-justify text-gray-500 lg:text-xl lg:px-10 lg:py-12">
              To empower businesses and individuals through innovative products
              and exceptional services, revolutionizing the digital landscape
              and driving sustainable growth in the digital era.
            </p>
          </blockquote>
        </div>
      </motion.div>
      <motion.div className="flex flex-col text-center w-[90%] shadow-md  p-4 rounded-lg  lg:w-[450px] lg:h-auto "
        variants={FadeInAnimation}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >

        <div className="flex items-center justify-center w-full h-full pt-10 pb-4 ">

          <img
            className="w-32 h-32 "
            src={ellipsee}
            alt="Ellipse"
          />
        </div>
        <div className="py-2 text-xl font-bold lg:text-4xl">
          Mission
          <blockquote>
            <p className="px-2 py-2 text-sm subpixel-antialiased font-normal text-justify text-gray-500 lg:text-xl lg:px-10 lg:py-12">
              Our mission is to be the trusted provider of comprehensive
              technology solutions, offering high-quality products and services
              that empower our clients to achieve their goals, increase
              efficiency, and stay ahead of the competition.
            </p>
          </blockquote>
        </div>
      </motion.div>


    </div>
  );
}
