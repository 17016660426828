import React from 'react'
import CareerPageLayout from '../../layout/CareerPageLayout'
import HRJob from '../../components/Career/JobPageContent/HRJob'

export default function HrPage() {
  return (
    <CareerPageLayout
      PageHeading={"Human Resource -Internship"}
      jobQuote="Nurturing Talent, Shaping Futures: HR Intern's Odyssey"
    >
      <HRJob />
    </CareerPageLayout>
  )
}
