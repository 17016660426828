import React from 'react'
import CareerPageLayout from '../../layout/CareerPageLayout'
import UiUxJob from '../../components/Career/JobPageContent/UiUxJob'

export default function UiUXPage() {
  return (
    <>
      <CareerPageLayout PageHeading={"UI/UX  Developer -Internship"}
        jobQuote="Deigning the future, One interaction at a time.. The UI/UX Developer’s Touch."

      >

        <UiUxJob />

      </CareerPageLayout>
    </>
  )
}
