import React from "react";
import AnimationCareerPage from "../components/Home/Animation";
import JobCards from "../components/Career/JobCards";
import { useEffect } from "react";

export default function Career() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const titleStyle = {
    background:
      "var(--gradient, linear-gradient(90deg, #EC568E 0%, #3110B5 100%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    display: "inline-block"
  };

  const content = {
    heading: `Shape Your Future with Tech Hive`,
    para: `Join our expert team and build your career with Tech Hive, where you can apply your existing skills, 
    develop new ones, and tackle complex projects while growing professionally.`
  }
  return (
    <div className="font-poppins">
      <div id="services" className="flex items-center justify-center px-4">
        <div className="py-[1.25rem] lg:w-5/6 w-full flex flex-col gap-3 justify-between md:items-center">
          <p className="pt-1 text-xl font-bold md:text-3xl lg:text-5xl lg:pt-8 ">
            <h1 className="md:text-center" style={titleStyle}>
              {content.heading}
            </h1>
          </p>
          <p className="w-full font-sans text-[12px] text-justify text-gray-600 md:text-center md:text-[16px] lg:text-xl lg:w-5/6">
            {content.para}
          </p>
        </div>
      </div>
      <div className="md:mt-8 ">
        <JobCards />
      </div>
      <div className="h-[2rem]" />
      <div className="md:my-16">
        <AnimationCareerPage />
      </div>
    </div>
  );
}
