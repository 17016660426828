import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../components/Home/HomeCarousel.css";
import HomeCarousel from "../components/Home/HomeCarousel";
import top_card from "../icons/home_building.svg";
import HomePageSvgAnimation from "../components/Home/Animation/Animation";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import FadeInAnimation from "../layout/FadeInAnimation";
import { useEffect } from "react";
export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const content = [
    {
      heading: "Placement Management",
      paragraph:
        "The Placement Management System (PMS) streamlines placements for students, institutions, and recruiters. It centralizes communication, job listings, applications, interviews, and progress tracking. Recruiters post jobs, manage applications, and evaluate candidates. Administrators enhance the process, generating reports. The PMS bridges the student-recruiter gap, fostering an efficient and transparent placement process.",
    },
    {
      heading: "Cab Management",
      paragraph:
        "The Cab Management System streamlines fleet operations for taxis, ridesharing, and corporate transport. It optimizes booking, dispatching, tracking, and reporting. Key features include real-time tracking, route optimization, driver management, automated billing, detailed reports, and safety measures. The system boosts efficiency, convenience, and security for passengers and drivers.",
    },
    {
      heading: "College Management",
      paragraph:
        "The College Management System automates administrative, academic, and operational processes in educational institutions. It includes student info management, course scheduling, exams and grading, admissions, attendance tracking, financial management, communication, library management, online learning, and more. It enhances efficiency, communication, and decision-making, simplifying administrative tasks and creating an organized learning environment.",
    },
    {
      heading: "Website Development",
      paragraph:
        "A website is a global platform displaying information, products, services, or content. It serves purposes like blogs, businesses, e-commerce, and education. Users access it via browsers, interacting with multimedia. Components include domain, hosting, pages, content, design, navigation, functionality, responsive design, SEO, analytics, security, and maintenance. Vital for communication, branding, engagement, websites are essential in the digital era.",
    },
    {
      heading: "Mobile App Development",
      paragraph:
        "Mobile app development service involves the design, creation, and deployment of applications for mobile devices like smartphones and tablets. This service encompasses everything from conceptualizing and coding the app to testing, refining, and launching it on various platforms, aiming to meet user needs and business goals.",
    },
    {
      heading: "Hostel Management",
      paragraph:
        "A hostel management system is a software solution designed to streamline and automate various administrative tasks in a hostel or dormitory setting. It encompasses functions like room allocation, check-ins, check-outs, billing, and occupancy tracking to efficiently manage hostel operations and enhance the resident experience.",
    },
  ];
  return (
    <div className="mb-6">
      <div className="flex rounded-md bg-[#131B291A]  ">
        <div className="flex flex-col gap-4 py-[2.1875rem] md:gap-8 px-8 md:px-16 my-auto md:h-3/4 font-poppins">
          <div className="font-bold text-[1.5rem] md:text-[3rem] md:w-3/4 md:leading-snug">
            <h1 className=" md:block hidden">
              Innovate <span className="text-header-text-blue">Tomorrow</span>{" "}
              with Tech Hive
            </h1>
            <h1 className=" md:hidden block">
              Innovate <span className="text-header-text-blue">Tomorrow</span>
              <div>

                with Tech Hive
              </div>
            </h1>
          </div>
          <div className="text-gray-500 md:text-2xl font-medium">
            <p>Joins us to shape the future with cutting-edge tech.</p>
            <p>Collaborate, Learn, and Thrive together.</p>
          </div>
          <motion.div
            className="flex justify-between gap-4 md:gap-8 items-center w-fit"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              ease: "anticipate",
              duration: 0.5
            }}
          >
            <button
            >
              <Link
                to="/career"
                className="px-4 md:px-6 py-2 rounded-md bg-header-text-blue hover:bg-[#346c95] text-white"
              >
                Apply Now
              </Link>
            </button>
            <button
            >
              <Link to="/aboutus" className="text-header-text-blue hover:text-[#31658a] font-medium">
                Learn More
              </Link>
            </button>
          </motion.div>
        </div>
        <div className="">
          <img src={top_card} alt="image3" className="hidden lg:block" />
        </div>
        {/* Attach Bg Image at bottom */}
      </div>
      {/* <div className={`relative md:hidden rounded-md `} style={{ backgroundImage: `url(${Background})`, backgroundPosition: "center", backgroundSize: "cover" }}>
        <div className="flex flex-col gap-4 py-[2.1875rem] md:gap-8 px-8 md:px-16 my-auto md:h-3/4 font-poppins drop-shadow-lg backdrop-blur-sm">
          <div className="font-bold text-[1.5rem] md:text-[3rem] md:w-3/4 md:leading-snug">
            <h1>
              Innovate <span className="text-header-text-blue">Tomorrow</span>{" "}
              with Tech Hive
            </h1>
          </div>
          <div className="text-black md:text-2xl font-medium">
            <p>Joins us to shape the future with cutting-edge tech.</p>
            <p>Collaborate, Learn, and Thrive together.</p>
          </div>
          <motion.div
            className="flex justify-between gap-4 md:gap-8 items-center w-fit"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              ease: "anticipate",
              duration: 0.5
            }}
          >
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <Link
                to="/career"
                className="px-4 md:px-6 py-2 rounded-md text-header-text-blue bg-white"
              >
                Apply Now
              </Link>
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <Link to="/aboutus" className="text-white font-medium">
                Learn More
              </Link>
            </motion.button>
          </motion.div>
        </div>
      </div> */}
      <h1 className="text-[#131B29] text-[1.4rem] md:text-[2.1875rem] lg:text-[2.8125rem] lg:text-left lg:mx-[4.375rem] font-semibold text-center py-6 font-poppins  ">
        What is our current
        <span className="text-[#2a7bb4]"> endeavor </span>?
      </h1>
      <HomePageSvgAnimation />

      <div className="py-2"></div>
      <motion.div
        variants={FadeInAnimation}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >
        <Carousel
          dots={true}
          autoPlay={true}
          infiniteLoop={true}
          transitionTime={1000}
          showStatus={false}
          interval={4000}
          showThumbs={false}
          showArrows={true}
          swipeable={false}
        >
          {content.map((content) => {
            return (
              <HomeCarousel
                key={content.heading}
                header={content.heading}
                paragraph={content.paragraph}
              />
            );
          })}
        </Carousel>
      </motion.div>
    </div>
  );
}
