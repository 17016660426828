import React from "react";
import img1 from "./Assets/corousal/pms1.svg";
import img2 from "./Assets/corousal/pms2.svg";
import img3 from "./Assets/corousal/pms3.svg";
import img4 from "./Assets/corousal/cms1.svg";
import img5 from "./Assets/corousal/cms2.svg";
import img6 from "./Assets/corousal/cms3.svg";
import img7 from "./Assets/corousal/clgmc1.svg";
import img8 from "./Assets/corousal/clgmc2.svg";
import img9 from "./Assets/corousal/clgmc3.svg";
import img10 from "./Assets/corousal/hms1.svg";
import img11 from "./Assets/corousal/hms2.svg";
import img12 from "./Assets/corousal/hms3.svg";
import "./Style.css";

const FinalAnimation = ({ animationOf }) => {
  const cards = [];
  if (animationOf === "pms") {
    cards.length = 0;
    cards.push(
      {
        index: 0,
        image: img1,
      },
      {
        index: 1,
        image: img2,
      },
      {
        index: 2,
        image: img3,
      }
    );
  } else if (animationOf === "cms") {
    cards.length = 0;
    cards.push(
      {
        index: 0,
        image: img4,
      },
      {
        index: 1,
        image: img5,
      },
      {
        index: 2,
        image: img6,
      }
    );
  } else if (animationOf === "clgms") {
    cards.length = 0;
    cards.push(
      {
        index: 0,
        image: img7,
      },
      {
        index: 1,
        image: img8,
      },
      {
        index: 2,
        image: img9,
      }
    );
  } else if (animationOf === "hms") {
    cards.length = 0;
    cards.push(
      {
        index: 0,
        image: img10,
      },
      {
        index: 11,
        image: img11,
      },
      {
        index: 12,
        image: img12,
      }
    );
  }
  // const cards = [
  //   {
  //     index: 0,
  //     image:
  //       "https://cdn.hashnode.com/res/hashnode/image/upload/v1610090876741/hAId9lkRU.png?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
  //   },
  //   {
  //     index: 1,
  //     image:
  //       "https://static.hbo.com/content/dam/hbodata/series/game-of-thrones/character/s5/daenarys-1920.jpg",
  //   },
  //   {
  //     index: 2,
  //     image:
  //       "https://static.hbo.com/content/dam/hbodata/series/game-of-thrones/character/bran-stark-1920.jpg?w=1200",
  //   },
  // ];
  return (
    <>
      {/* <div className=" relative w-full h-full flex justify-center items-center      ">
      <div className="  absolute w-[400px] h-[700px]   card1 flex justify-center items-center">
        
        <img src={cards[0].image} alt="" />
      </div>
      <div className="  absolute w-[400px] h-[700px] card2 flex justify-center items-center">
        
        <img src={cards[1].image} alt="" />
      </div>
      <div className="  absolute w-[400px] h-[700px] card3 flex justify-center items-center">
        
        <img src={cards[2].image} alt="" />
      </div>
    </div> */}

      <img
        className="  absolute  w-[370px]    card1 "
        src={cards[0].image}
        alt=""
      />

      <img
        className="  absolute  w-[370px]     card2 "
        src={cards[1].image}
        alt=""
      />

      <img
        className="  absolute  w-[370px]     card3 "
        src={cards[2].image}
        alt=""
      />
    </>
  );
};

export default FinalAnimation;
