import React from "react";
import BgImage from "./img/Group 185.svg";
import BgImagesm from "./img/Group 317.svg";

export default function Aboutus() {
  return (
    <div className="w-full">
      <img className="bg-cover block lg:hidden" src={BgImagesm} alt="Background" />
      <img className="bg-cover hidden lg:block" src={BgImage} alt="Background" />
    </div>
  );
}
