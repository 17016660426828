import React from "react";
import { Link } from "react-router-dom";

export default function HeaderNavbarChild({ link, img, title, desc }) {
  return (
    <>
      <Link className=" w-full h-full  justify-around items-center flex" to={link}>
        <div className="flex gap-2 justify-center group">
          <img src={img} alt="icon" className="h-6 mt-2" />
          <div className=" w-full">
            <p className="text-lg font-medium group-hover:text-[#EC568E]">
              {title}
            </p>
            <p className="text-xs text-gray-500 max-w-xs group-hover:text-[#EC568E]">
              {desc}
            </p>
          </div>
        </div>
      </Link>
    </>
  );
}
