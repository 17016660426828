import React from 'react'
import ContentForCareer from '../ContentForCareer';
import StipendStructure from '../StipendStructure';
import TechnicalRequirements from '../TechnicalRequirements';

export default function HRJob() {
    // Responsibilities
const responsibilities = [
    "Assist with recruitment and onboarding processes, including posting job openings, screening resumes, and conducting initial interviews.",
    "Support the HR team in maintaining employee records and ensuring compliance with company policies and procedures.",
    "Assist in organizing and coordinating employee training and development programs.",
    "Participate in the performance management process by assisting with performance evaluations and providing feedback to employees.",
    "Contribute to the development and implementation of HR policies and initiatives.",
    "Assist with employee engagement activities and events.",
    "Support the HR team in handling employee relations issues and resolving conflicts.",
    "Assist with HR administrative tasks, such as preparing HR documents and maintaining HR databases."
  ];
  
  // Requirements
  const requirements = [
    "Pursuing or completed a degree in Human Resources, Business Administration, or a related field.",
    "Strong communication and interpersonal skills.",
    "Proficient in Microsoft Office Suite (Word, Excel, PowerPoint).",
    "Excellent organizational and time management abilities.",
    "Attention to detail and accuracy in record-keeping.",
    "Ability to maintain confidentiality and handle sensitive information.",
    "Enthusiastic and proactive attitude towards learning and contributing.",
    "Strong desire to understand HR processes and practices.",
    "Adaptability to a dynamic work environment."
  ];
  
  // Additional Requirements
  const additionalRequirements = [
    "Familiarity with HR software or tools.",
    "Previous experience in HR-related projects or coursework.",
    "Knowledge of labor laws and regulations.",
    "Ability to assist in recruitment activities, such as resume screening and interview coordination.",
    "Interest in employee engagement initiatives and event planning.",
    "Basic understanding of performance management and training programs.",
    "Proficiency in data analysis and reporting.",
    "Comfortable presenting and communicating information to small groups."
  ];
  const PrimarySkills=["Communication","Adaptation","Leadership","Problem Solving",]
  
  return (
    <>
    <ContentForCareer
      heading={"Responsibilities"}
      ArrayForContent={responsibilities}
    />
    <ContentForCareer
      heading={"Requirement"}
      ArrayForContent={requirements}
    />
    <ContentForCareer
      heading={"Additional Requirements"}
      ArrayForContent={ additionalRequirements}
    />
    <StipendStructure />
    <TechnicalRequirements
      PrimarySkills={PrimarySkills}
    /></>
  )
}
