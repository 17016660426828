const FadeInAnimation = {
    initial: {
        opacity: 0,
        y: 30,
    },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            delay: 0.15,
            duration: 0.25
        }
    },
};

export default FadeInAnimation;