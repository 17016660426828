import React from "react";
import ContentForCareer from "../ContentForCareer";
import StipendStructure from "../StipendStructure";
import TechnicalRequirements from "../TechnicalRequirements";

export default function UiUxJob() {
  // Responsibilities
  const responsibilities = [
    "Design and Develop Engaging Web Pages",
    "Troubleshoot and Resolve Design Challenges",
    "Create Prototypes for User Experience Enhancement",
    "Proficiently Transform Prototypes into Responsive HTML Designs",
    "Collaborate with cross-functional teams to gather design requirements and align UI/UX vision.",
    "Conduct user research and analyze feedback to continuously improve the user experience.",
    "Ensure designs adhere to accessibility standards and provide an inclusive user interface.",
    "Stay updated with emerging design trends and technologies to enhance design practices.",
    "Effectively communicate design concepts and rationales to stakeholders and clients.",
  ];

  // Requirements
  const requirements = [
    "Proficiency in User-Centered Design principles and practices.",
    "Strong skills in UI design, including wireframing, prototyping, and visual design.",
    "Proficiency in HTML, CSS, and responsive design techniques.",
    "Experience with design tools such as Figma, Adobe XD, or Sketch.",
    "Familiarity with usability testing and user research methodologies.",
    "Ability to transform design concepts into interactive and engaging user interfaces.",
    "Knowledge of front-end development languages and frameworks (e.g., JavaScript, React)",
    "Understanding of accessibility standards and guidelines.",
    "Creative problem-solving skills and attention to detail.",
    "Strong communication skills to effectively collaborate with cross-functional teams.",
    "Awareness of the latest design trends and industry best practices.",
    "Portfolio showcasing a range of design projects and user-centered solutions.",
  ];

  // Additional Requirements
  const additionalRequirements = [
    "Experience in animation, motion design, or illustration.",
    "Familiarity with backend technologies and database concepts.",
    "Proficiency in other design tools or platforms.",
    "Knowledge of mobile app design principles and guidelines.",
    "Understanding of A/B testing and data-driven design decisions.",
    "Experience in conducting user interviews and surveys",
    "Strong understanding of branding and visual identity design.",
    "Knowledge of SEO principles and best practices for web design.",
  ];
  const PrimarySkills = [
    "For the Design Software : Figma",
    "Knowledge Need : Web development , App development",
    "For the Soft skill : Communication, Adaptation.",
  ];
  return (
    <>
      <ContentForCareer
        heading={"Responsibilities"}
        ArrayForContent={responsibilities}
      />
      <ContentForCareer
        heading={"Requirement"}
        ArrayForContent={requirements}
      />
      <ContentForCareer
        heading={"Additional Requirements"}
        ArrayForContent={additionalRequirements}
      />
      <StipendStructure />
      <TechnicalRequirements PrimarySkills={PrimarySkills} />
    </>
  );
}
