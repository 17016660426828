import React from "react";
import "./featues.css";

import Flipcard from "../../components/Product&Service/Flipcard";
import frontimg1 from "../../components/Product&Service/img/for management (18).svg";
import frontimg2 from "../../components/Product&Service/img/for management (19).svg";
import frontimg3 from "../../components/Product&Service/img/for management (20).svg";
import backimg1 from "../../components/Product&Service/img/for management (21).svg";
import backimg2 from "../../components/Product&Service/img/for management (22).svg";
import backimg3 from "../../components/Product&Service/img/for management (23).svg";
import OtherHeading from "../../components/Product&Service/OtherHeading";

import LayoutPlacementPage from "../../components/Product&Service/LayoutPlacementPage";
import HostelCard from "../../components/Product&Service/HostelCard";

import { motion } from "framer-motion";

import FadeInAnimationMultiple from "../../layout/FadeInAnimationMultiple";
import FinalAnimation from "../../components/Product&Service/Animationsbanner/FinalAnimation";

export default function HotelManagement() {
  const contents = [
    {
      frontimg: frontimg1,
      backimg: backimg1,
    },
    {
      frontimg: frontimg2,
      backimg: backimg2,
    },
    {
      frontimg: frontimg3,
      backimg: backimg3,
    },
  ];

  return (
    <>
      <LayoutPlacementPage
        headingBlack="Empowering  "
        headingRed="Creativity"
        headingBlack2="United"
        headingRed2="Visions"
        para="Software managing visual content compilation, organization, and sharing in educational, artistic, or professional contexts."
        Img={<FinalAnimation animationOf={"hms"} />}
        children={
          <>
            <div className="full bg-gradient-to-tr from-[#ec568d2a] py-[15px] pb-8  to-[#3110b531] h-full w-full">
              <div className="flex flex-wrap justify-around w-full gap-4 justify-items-center md:gap-1 lg:gap-6 ">
                <h1 className="text-[#131B29] pl-2 lg:pl-[20px] font-[650] mb-[15px] lg:mb-[25px] lg:my-[35px] md:text-base text-[1.7rem] md:text-[2rem] lg:text-[2.3rem] f ">
                  Elements of our{" "}
                  <span className="text-[#3110B5]">Innovation</span>
                </h1>
                <h1 className=" hidden lg:block invisible text-[#131B29]  md:text-base text-[1.7rem] md:text-[2rem] lg:text-[2.3rem] font-semibold">
                  Aspects of our{" "}
                  <span className="text-[#3110B5]">Creation</span>
                </h1>
                <h1 className=" hidden lg:block invisible text-[#131B29]  md:text-base text-[1.7rem] md:text-[2rem] lg:text-[2.3rem] font-semibold">
                  Aspects of our{" "}
                  <span className="text-[#3110B5]">Creation</span>
                </h1>
              </div>

              <div className="flex flex-wrap justify-around w-full gap-2 justify-items-center sm:gap-3 md:gap-4 lg:gap-6">
                {contents.map((content, index) => {
                  return (
                    <motion.div
                      variants={FadeInAnimationMultiple}
                      initial="initial"
                      whileInView="animate"
                      viewport={{
                        once: true,
                      }}
                      custom={index}
                    >
                      <Flipcard
                        key={index}
                        frontimg={content.frontimg}
                        backimg={content.backimg}
                      />
                    </motion.div>
                  );
                })}
              </div>
            </div>
            <div    className="p-4 sm:p-6 md:p-8 lg:p-1">
              <OtherHeading
                heading="Explore More Features"
                para="Automate course management, grades, communication, attendance, resources, analytics, advising, collaboration, and faculty information efficiently."
              />
            </div>
            <div className="mb-6">
              <HostelCard />
            </div>
          </>
        }
      />
    </>
  );
}
