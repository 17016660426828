import React, { useState } from "react";
import axios from "axios";

import modalImg from "./img/modalImg.svg";
import { motion } from "framer-motion";
import Backdrop from "./BackDrop";
import Validation from "../../layout/Validation";

export default function Modal({ handleClose }) {
  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };
  const titleStyle = {
    background:
      "var(--gradient, linear-gradient(90deg, #EC568E 0%, #3110B5 100%))",
    backgroundClip: "text",
    fontWeight: "bold",

    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    display: "inline-block",
    textDecoration: "underline", // Add underline style
    textDecorationColor: "transparent", // Make underline transparent
  };
  const [formData, setFormData] = useState({
    name: "",
    contactNo: "",
    email: "",
    organization: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error when the field value changes
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (formData.name.trim() === "") {
      errors.name = "Name is required";
    }

    if (formData.contactNo.trim() === "") {
      errors.contactNo = "Contact number is required";
    } else if (!/^\d{10}$/.test(formData.contactNo)) {
      errors.contactNo = "Invalid mobile number";
    }

    if (formData.email.trim() === "") {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email format";
    }

    if (formData.organization.trim() === "") {
      errors.organization = "Message is required";
    }

    return errors;
  };

  const isValidEmail = (email) => {
    // Basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const response = await axios.post(
        "https://api.techhivetechnology.com/send-email",
        formData
      );

      console.log(response.status); // Log the HTTP status code

      if (response.status === 200) {
        // Display success message with formatted template
        const successMessage = `Thank you for booking a Free Demo!\nWe will reach out to you soon.`;
        alert(successMessage);

        // Clear form fields
        setFormData({
          name: "",
          contactNo: "",
          email: "",
          organization: "",
        });

        setErrors({});
      } else {
        // Display error message
        alert("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.log(error.message); // Handle any errors
      // Display error message
      alert("Something went wrong. Please try again.");
    }
  };

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="modal flex justify-center items-center w-full h-full"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className=" w-full h-full justify-center flex items-center">
          <div className=" w-[23rem] lg:w-[62.5rem]  my-auto flex py-4 md:py-0 bg-white justify-center items-center rounded-lg">
            <div className=" w-full h-full lg:block hidden">
              <img className=" w-full h-full" src={modalImg} alt="" />
            </div>

            <div className=" w-full h-full ">
              <div className=" flex flex-col px-10  flex-wrap md:basis-2/5  ">
                <div className=" flex justify-end items-center  w-full">
                  <button
                    onClick={handleClose}
                    className=" flex justify-center items-center  h-8   w-8  rounded-full bg-gray-50   mb-4"
                  >
                    <button className=" text-gray-400 text-3xl  text-center w-full h-full mb-3 ">
                      x
                    </button>
                  </button>
                </div>

                <h1
                  className="underline text-center underline-offset-2 text-[1.5rem] w-full  lg:text-[2.1875rem]"
                  style={titleStyle}
                >
                  Book your demo model
                </h1>
                <div className=" h-[2.75rem] self-stretch  my-5 px-[0.875rem] gap-[0.5rem]">
                  <input
                    name="name"
                    className="w-full lg:w-[30rem] h-full  px-4 py-2   text-lg bg-white border border-gray-300 rounded-md shadow-xs outline-none"
                    type="text"
                    placeholder="Your name*"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <Validation error={errors.name} />
                </div>
                <div className=" h-[2.75rem] self-stretch my-5 px-[0.875rem] gap-[0.5rem]">
                  <input
                    name="contactNo"
                    className="w-full lg:w-[30rem] h-full  px-4 py-2   text-lg bg-white border border-gray-300 rounded-md shadow-xs outline-none"
                    type="number"
                    placeholder="Your Phone No*"
                    value={formData.contactNo}
                    onChange={handleChange}
                  />
                  <Validation error={errors.contactNo} />
                </div>
                <div className=" h-[2.75rem] self-stretch my-5 px-[0.875rem] gap-[0.5rem]">
                  <input
                    className="w-full lg:w-[30rem] h-full  px-4 py-2   text-lg bg-white border border-gray-300 rounded-md shadow-xs outline-none"
                    type="email"
                    name="email"
                    placeholder="Your email*"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <Validation error={errors.email} />
                </div>
                <div className="   h-[2.75rem] self-stretch my-10 px-[0.875rem] gap-[0.5rem]">
                  <textarea
                    className="w-full lg:w-[30rem] px-4 text-lg bg-white border border-gray-300 rounded-md shadow-xs outline-none"
                    placeholder="Your Message *"
                    name="organization"
                    rows={5}
                    value={formData.organization}
                    onChange={handleChange}
                  />
                  <Validation error={errors.organization} />
                </div>
                <div className="flex justify-center h-full ">
                  <button
                    onClick={handleSubmit}
                    className="rounded-lg w-[7.5rem] p-2 text-white mt-[6.25rem] bg-[#EC568E] hover:bg-[#dd4d81] rounder-lg"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
}
