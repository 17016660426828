import React from "react";

import { motion } from "framer-motion";
 import FadeInAnimationMultiple from "../../layout/FadeInAnimationMultiple";

export default function OtherServiceCard({ arr }) {
  const cardStyle = {
    border: "0.125rem solid transparent",
    background: "linear-gradient(90deg, #EC568E 0%, #3110B5 100%)",
    borderRadius: "0.375rem",
  };

  return (
    <div className="flex flex-col lg:flex-row gap-6 lg:gap-16 items-center justify-center p-2 lg:p-12 w-full h-auto ">
      {arr.map((item, index) => (
        <motion.div
          className="flex flex-col lg:w-[20.9375rem] w-[22.5rem] h-[19.375rem] items-center lg:h-[21.875rem] justify-between lg:rounded-xl  bg-white  shadow-2xl"
          key={index}
          style={cardStyle}
          variants={FadeInAnimationMultiple}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          custom={index}
        >
          <div className="flex p-2 lg:p-7 flex-col h-full items-center justify-center bg-white rounded-sm ">
            <h2 className="text-2xl lg:text-3xl  font-bold text-blue-700">
              {item.title}
            </h2>
            <p className="lg:mt-10 mt-12 text-center  lg:font-normal text-xl lg:text-2xl text-gray-500 tracking-wide">
              {item.content}
            </p>
          </div>
        </motion.div>
      ))}
    </div>
  );
}
