import React from "react";
import logo from "../Product&Service/img/chennaicablogo.svg";

import { motion } from "framer-motion";
import FadeInAnimation from "../../layout/FadeInAnimation";

export default function LogaContent() {
  const titleStyle = {
    background:
      "var(--gradient, linear-gradient(90deg, #EC568E 0%, #3110B5 100%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    display: "inline-block",
  };
  return (
    <div className="text-center items-center  mt-20  flex justify-center flex-col">
      <div className="text-2xl  mb-1 lg:mb-10 lg:text-4xl font-bold" style={titleStyle}>
        Our Clients
      </div>
      <div className=" w-1/2 lg:w-[90%]   h-[0.225rem] rounded-md mx-10 bg-[#c4c6ca] "></div>
      <motion.img
        className="w-1/2 lg:w-[31.25rem] lg:h-[31.25rem] md:w-[12.5rem] md:h-[18.75rem] p-5"
        src={logo}
        alt="/"
        variants={FadeInAnimation}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      ></motion.img>
    </div>
  );
}
