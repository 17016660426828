import React from "react";
import HeaderCard from "../../components/Product&Service/HeaderCard";
import OtherHeading from "../../components/Product&Service/OtherHeading";
import OtherService from "../../components/Product&Service/OtherService";
import LogoContent from "../../components/Product&Service/LogoContent";
import ContactUs from "../../components/Product&Service/ContactUs";
import premium from "../../components/Product&Service/img/premium.svg";
import BannerLayout from "../../components/Product&Service/BannerLayoutApp";
export default function WebsitePg() {
  return (
    <>
      <BannerLayout
        headingBlack="A more"
        headingRed="prosperous"
        headingBlack2="life starts "
        headingRed2="with us"
        para1="We create customized websites to match your needs"
        para2=" handling everything from design and development to launch"
        para3="   and upkeep."
        children={
          <>
            <HeaderCard img={premium} />
           
          </>
        }
      />
      
       <OtherHeading
              heading="Other Services"
              para="Experience the ultimate website with unparalleled service. We pride
      ourselves on delivering the finest tech."
            />
            <OtherService />
            <LogoContent />
            <ContactUs />
           
    </>
  );
}
