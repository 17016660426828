import React from "react";

import { motion } from "framer-motion";
import FadeInAnimation from "../../layout/FadeInAnimation";

export default function History() {
  return (

    <motion.div className="flex flex-col bg-blue-200 lg:h-[350px] w-full text-center px-2 py-6 lg:px-24 mt-10"
      variants={FadeInAnimation}
      initial="initial"
      whileInView="animate"
      viewport={{
        once: true,
      }}
    >
      <h1 className="font-bold text-bold pb-4 text-blue-800 text-2xl lg:text-3xl mb-4 lg:mb-6">
        Our History
      </h1>
      <blockquote className="text-base lg:text-lg">
        <p className="font-normal text-sm lg:text-xl text-justify px-3 md:px-4 mb-2 lg:mb-4 ">
          TechHive Technologies, established in October 2022, is a rising IT
          solution firm specializing in designing, developing, and managing
          precise IT solutions for businesses of all sizes. Whether you're a
          small, medium, or large enterprise, we possess the expertise and
          capabilities to deliver end-to-end solutions that are accurate,
          scalable, and of superior quality while maintaining
          cost-effectiveness. Our focus lies in constructing highly scalable
          digital business solutions that facilitate company growth and enable
          the realization of their maximum potential both online and offline.
          With a keen emphasis on reaching prospects, converting customers, and
          ensuring customer retention, we approach every project, regardless of
          its magnitude, with these growth-oriented objectives in mind.
        </p>
      </blockquote>
    </motion.div>
  );
}
