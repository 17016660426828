import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect } from "react";

import Modal from "./ModalPopUp";

export default function LayoutPlacementPage({
  headingBlack,
  headingRed,
  headingBlack2,
  headingRed2,
  para,
  Img,

  children,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [modalOpen, setModalOpen] = useState(false);

  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);
  return (
    <>
      <div className=" w-full h-full ">
        <div className=" my-4 mx-2">
          <div className="w-full  h-auto flex justify-around items-center bg-[#2a7ab428]">
            <div className="w-full h-full flex  justify-center   items-center">
              <div className=" w-full  hidden  lg:block   h-[500px] relative ">
                {Img}
              </div>
              <div className="flex flex-col gap-4  pl-5  w-full py-[0.125rem]   md:gap-6     ">
                <div className="font-bold text-[2rem] md:text-[3.3rem]  w-full md:leading-snug">
                  <h1>
                    {headingBlack}{" "}
                    <span className="text-[#EC568E]">{headingRed}</span>{" "}
                  </h1>
                  <h1>
                    {headingBlack2}{" "}
                    <span className="text-[#EC568E]"> {headingRed2}</span>{" "}
                  </h1>
                </div>
                <div className="text-gray-500 md:text-2xl pr-4  lg:pr-20 font-medium">
                  <p> {para}</p>
                </div>
                <div className=" flex justify-end  pr-8 lg:justify-center mb-4 items-center w-full">
                  <div className="flex justify-start  lg:justify-center gap-4 md:gap-8 items-center w-fit">
                    <button
                      onClick={open}
                      className="px-5 md:px-6 py-2 md:py-2 text-lg rounded-md font-semibold  bg-[#EC568E] hover:bg-[#dd4d81] text-white"
                    >
                      Book Demo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="items-center   mt-10  lg:mt-20     flex-col w-full gap-9 justify-center flex">
          {children}
        </div>
        <AnimatePresence
          initial={false}
          wait={true}
          onExitComplete={() => null}
        >
          {modalOpen && <Modal modalOpen={modalOpen} handleClose={close} />}
        </AnimatePresence>
      </div>
    </>
  );
}
