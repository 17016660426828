import { BrowserRouter, Route, Routes } from "react-router-dom";
import FullStackPage from "./pages/career/FullStackPage";
import UiUXPage from "./pages/career/UiUXPage";
import ReactNative from "./pages/career/ReactNative";
import HrPage from "./pages/career/HrPage";
import Career from "./pages/Career";
import PlacementManagement from "./pages/Product&services/PlacementManagement";
import Header from "../src/layout/Header";
import Footer from "../src/layout/Footer";
import HotelManagement from "./pages/Product&services/HotelManagement";
import CabManagement from "./pages/Product&services/CabManagement";
import CollegeManagement from "./pages/Product&services/CollegeManagement";
import AppPg from "./pages/Product&services/AppPg";
import WebsitePg from "./pages/Product&services/WebsitePg";
import ReachUs from "./pages/ReachUs";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";



function App() {
  return (
    <div className="flex flex-col rounded-lg bg-white ">
      <BrowserRouter>
        <Header />
        <div className="h-14"></div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />{" "}
          <Route path="/reachus" element={<ReachUs />} />
          <Route path="/career" element={<Career />} />





          <Route
            path="/placementmanagement"
            element={<PlacementManagement />}
          />
          <Route path="/cabmanagement" element={<CabManagement />} />
          <Route path="/hostelmanagement" element={<HotelManagement />} />
          <Route path="/collegemanagement" element={<CollegeManagement />} />
          <Route path="/apppage" element={<AppPg />} />
          <Route path="/websitepage" element={<WebsitePg />} />
          <Route path="/career/fullStack" element={<FullStackPage />} />
          <Route path="/career/uiux" element={<UiUXPage />} />
          <Route path="/career/hr" element={<HrPage />} />
          <Route path="/career/reactnative" element={<ReactNative />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
