import React from "react";

import { motion } from "framer-motion";
// import FadeInAnimation from "../../layout/FadeInAnimation";
import FadeInAnimationMultiple from "../../layout/FadeInAnimationMultiple";

export default function FeatureCard({ arr, height }) {
  return (
    <div className="flex flex-wrap justify-around bg-white   gap-12 lg:gap-[2rem] lg:pt-1 lg:pb-6 lg:px-6 md:p-2  ">
      {arr.map((item, index) => (
        <motion.div
          className={`lg:w-[23.75rem] w-[20rem]  ${height}  flex flex-col p-4  transition-all ease-in-out border border-gray-300 p-6   shadow-xl    duration-500 transform text-pink-600 hover:text-blue-600   rounded-md  overflow-hidden`}
          key={index}
        
          variants={FadeInAnimationMultiple}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          custom={index}
        >
          <img
            className="w-16 h-16 mx-auto lg:w-20 lg:h-20"
            src={item.image}
            alt="item"
          />
          <h1 className="mt-5 mb-2 text-xl font-bold text-center lg:text-2xl ">
            {item.title}
          </h1>
          <div className="overflow-y-auto text-base text-center text-gray-800 hover:text-gray-600 lg:text-xl mt-9">
            <blockquote>
              <p className="text-sm text-justify lg:text-base ">{item.content}</p>
            </blockquote>
          </div>
        </motion.div>
      ))}
    </div>
  );
}
