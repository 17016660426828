import React from "react";
import { motion } from "framer-motion";

export default function Button({ buttonName }) {
  return (
    <button className="flex justify-start items-center ">
      <div>
        <div className=" px-3  lg:px-3   flex justify-center items-center  h-8 lg:h-10 flex-shrink-0 bg-white hover:bg-gray-300 transition-all rounded-[3px]">
          <span className="font-poppins text-center   text-[16px] lg:text-[18px] font-medium bg-gradient-to-r from-pink-500 to-purple-900 bg-clip-text text-transparent">
            {buttonName}
          </span>
        </div>
      </div>
    </button>
  );
}
