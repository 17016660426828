import React from "react";
import ContactCards from "../components/ReachUs/ContactCards";
import addressicon from "../components/ReachUs/img/location (1) 1.svg";
import emailicon from "../components/ReachUs/img/Vectorr.svg";
import callicon from "../components/ReachUs/img/Vector (1).svg";
import ReachusForm from "../components/ReachUs/ReachusForm";
import { motion } from "framer-motion";
import FadeInAnimation from "../../src/layout/FadeInAnimation";
import FadeInAnimationMultiple from "../../src/layout/FadeInAnimationMultiple";
import { useEffect } from "react";


export default function ReachUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const contents = [
    {
      icon: addressicon,
      para: "Plot No.11, 24th, 2 Nd Cross Street,Kandan Avenue JB Estate, Avadi",
      btntitle: "View",
      link: ""

    },
    {
      icon: emailicon,
      para: "admin@techhivetechnology.com",
      btntitle: "Email Us",
      link: "mailto:admin@techhivetechnology.com"
    },
    {
      icon: callicon,
      para: "+91 89257 99512",
      btntitle: "Call Us",
      link: "tel:+918925799512"
    },
  ];
  return (
    <>
      <motion.div className="flex w-full flex-col justify-center items-center py-4">
        <div className="flex flex-col text-center ">
          <h1 className="text-[2.5rem] tracking-wide mt-1 lg:mt-5 font-[Poppins] font-bold text-[#EC568E]">
            Reach Us
          </h1>
          <h6 className="md:text-[1.25rem] font-['inter'] text-[#898d94] text-center px-4">
            Get in touch and let us know how we can help
          </h6>
        </div>
        <motion.div className="flex  lg:gap-24 gap-5 justify-center w-full flex-wrap">
          {contents.map((content, index) => {
            return (
              <motion.div
                variants={FadeInAnimationMultiple}
                initial="initial"
                whileInView="animate"
                viewport={{
                  once: true,
                }}
                custom={index}

              >
                <ContactCards
                  key={content.icon}
                  icon={content.icon}
                  para={content.para}
                  btntitle={content.btntitle}
                  link={content.link}
                />
              </motion.div>
            );
          })}
        </motion.div>
        <div className="w-[90%] mt-[5rem] h-[0.125rem] bg-[#c4c6ca]"></div>
        <motion.div
          variants={FadeInAnimation}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
          className=" w-full"
        >
          <ReachusForm />

        </motion.div>
      </motion.div>
    </>
  );
}
