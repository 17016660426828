import React, { useEffect } from "react";
import About from "../components/AboutUs/Aboutus";
import Vision from "../components/AboutUs/Vision";
import History from "../components/AboutUs/History";
import OtherHeading from "../components/Product&Service/OtherHeading";
import Animation from "../components/AboutUs/animation/Animation";
export default function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="font-poppins">
      <About />
      <OtherHeading heading="Our Vision & Mission" />
      <Vision />
      <Animation />
      <History />
    </div>
  );
}
