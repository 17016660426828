// import React from "react";
// import img from "../ReachUs/img/image 2.svg";

// function ReachusForm() {
//   return (
//     <div className="flex  justify-center  ">
//       <div className="basis-3/5 hidden md:block">
//         <img src={img} alt="sideimg" className="w-full h-[600px]" />
//       </div>
//       <div className=" flex flex-col  flex-wrap m-10 md:basis-2/5  ">
//         <h1 className="text-[20px] md:text-[40px] lg:text-[40px]tracking-wide  font-[Poppins] font-bold text-[#EC568E] ">
//           Request a free consultation
//         </h1>
//         <div className=" h-[44px] self-stretch  my-5 px-14px gap-8px font-inter">
//           <input
//             className="w-full lg:w-[500px] h-full  px-4 py-2  my-5 text-lg bg-white border border-gray-300 rounded-md shadow-xs outline-none"
//             type="text"
//             placeholder="Your name*"
//           />
//         </div>
//         <div className=" h-[44px] self-stretch my-5 px-14px gap-8px font-inter">
//           <input
//             className="w-full lg:w-[500px] h-full  px-4 py-2  my-5 text-lg bg-white border border-gray-300 rounded-md shadow-xs outline-none"
//             type="email"
//             placeholder="Your email*"
//           />
//         </div>
//         <div className="  h-auto self-stretch my-10 px-14px gap-8px font-inter">
//           <textarea
//             className="w-full lg:w-[500px]   px-4 text-lg bg-white border border-gray-300 rounded-md shadow-xs outline-none"
//             rows={5}
//             placeholder="Your Message"
//           />
//         </div>

//         <div className="flex justify-center items-center     lg:w-[500px]">
//           <button className="rounded-lg w-[120px] font-inter p-2 text-white  bg-[#111DBF] rounder-lg">
//             Submit
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ReachusForm;
import React, { useState } from "react";
import axios from "axios";
import img from "../ReachUs/img/image 2.svg";
import Validation from "../../layout/Validation";
import { motion } from "framer-motion";

function ReachusForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "message":
        setMessage(value);
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    const errors = {};

    if (name.trim() === "") {
      errors.name = "Name is required";
    }

    if (email.trim() === "") {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email format";
    }

    if (message.trim() === "") {
      errors.message = "Message is required";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const formData = {
      name: name,
      email: email,
      message: message,
    };

    try {
      const response = await axios.post(
        "https://api.techhivetechnology.com/send-email-consultation",
        formData
      );

      if (response.status === 200) {
        alert(
          "Thank you for requesting a free consultation.\nWe will reach out to you soon."
        );
        setName("");
        setEmail("");
        setMessage("");
        setErrors({});
      } else {
        alert("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="flex  justify-center  ">
      <div className="basis-3/5 hidden md:block">
        <img src={img} alt="sideimg" className="w-full h-[37.5rem]" />
      </div>
      <div className="flex flex-col flex-wrap m-10 md:basis-2/5  ">
        <h1 className="text-[1.25rem] md:text-[2rem] tracking-wide  font-[Poppins] font-bold text-[#EC568E]">
          Request a free consultation
        </h1>
        <div className="h-[2.75em] self-stretch my-5 gap-[0.5rem] font-inter">
          <input
            name="name"
            value={name}
            onChange={handleChange}
            className="w-full lg:w-[31.25rem] h-full px-4 py-2  text-lg bg-white border border-gray-300 rounded-md shadow-xs outline-none"
            type="text"
            placeholder="Your name*"
          />
          <Validation error={errors.name} />
        </div>
        <div className="h-[2.75rem] self-stretch my-5 gap-[0.5rem] font-inter">
          <input
            name="email"
            value={email}
            onChange={handleChange}
            className="w-full lg:w-[31.25rem] h-full px-4 py-2  text-lg bg-white border border-gray-300 rounded-md shadow-xs outline-none"
            type="email"
            placeholder="Your email*"
          />
          <Validation error={errors.email} />
        </div>
        <div className="h-auto self-stretch my-5 gap-[0.5rem] font-inter">
          <textarea
            name="message"
            value={message}
            onChange={handleChange}
            className="w-full lg:w-[31.25rem] py-2 px-4 text-lg bg-white border border-gray-300 rounded-md shadow-xs outline-none"
            rows={5}
            placeholder="Your Message*"
          />
          <Validation error={errors.message} />
        </div>
        <div className="flex justify-center items-center mt-4 lg:w-[31.25rem]">
          <button
            onClick={handleSubmit}
            className="rounded-lg w-[7.5rem] font-inter p-2 text-white bg-[#111DBF] hover:bg-[#1b1f55] rounder-lg"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReachusForm;
