import React from "react";
import shedule from "./img/event-management 1.svg";
import attendance from "./img/attendance 2.svg";
import timemoney from "./img/time-is-money 3.svg";
import message from "./img/digital 1.svg";
import library from "./img/book 1.svg";
import feedback from "./img/feedback 5.svg";
import FeatureCard from "./FeatureCard";

const CollegeData = [
  {
    title: "Event Scheduling",
    content:
      "Our college management system simplifies event scheduling, optimizing resource utilization and improving the overall college experience by efficient planning and communication.",
    image: shedule,
  },
  {
    title: "Attendance Management",
    content:
      " Attendance management in a college system involves tracking students' presence, ensuring policy compliance, and maintaining accurate records for administrative purposes, supporting student engagement.",
    image: attendance,
  },
  {
    title: "Time Saving",
    content:
      "Our college management system streamlines processes, enhances productivity, and creates an efficient digital platform for staff and students, improving overall educational operations.",
    image: timemoney,
  },
  {
    title: "Information Sharing",
    content:
      "Efficient, secure data sharing in our college management system connects students, faculty, staff, and administrators, ensuring easy access while prioritizing data security and privacy.",
    image: message,
  },
  {
    title: "Feedback Management",
    content:
      "In our college management system, feedback management systematically collects, analyzes, and utilizes input from students, faculty, and stakeholders to improve education, administration, and services.",
    image: feedback,
  },
  {
    title: "Library Management",
    content:
      "Our college management system ensures seamless library resource management, enhancing research capabilities and supporting academic needs with efficient organization and integration.",
    image: library,
  },
];
export default function CollegeCard() {
  return <FeatureCard arr={CollegeData} height="  lg:h-[25rem] h-[22rem] " />;
}
