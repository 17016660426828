import React from "react";
import CareerPageLayout from "../../layout/CareerPageLayout";
import FullStackJob from "../../components/Career/JobPageContent/FullStackJob";

export default function FullStackPage() {
  return (
    <>
      <CareerPageLayout 
      link="https://docs.google.com/forms/d/e/1FAIpQLSewf97PFwv2tmMYsmM8-dLS7qBm8G9QHE9jaV01q5mTft76FA/closedform"
      >
        <FullStackJob/>
      </CareerPageLayout>
    </>
  );
}
