import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import FadeInAnimation from "../../layout/FadeInAnimation";

export default function ContactUs() {
  return (
    <motion.div
      className="flex flex-col items-center gap-8   py-6  bg-blue-200 w-full "
      variants={FadeInAnimation}
      initial="initial"
      whileInView="animate"
      viewport={{
        once: true,
      }}
    >
      <h1 className="font-bold text-xl lg:text-3xl underline underline-offset-8 ">
        Tell us your requirements!
      </h1>{" "}
      <motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
        <Link
          to="/reachus"
          className="bg-[#3110B5] text-white py-2 px-4   lg:text-xl rounded-md font-semibold"
        >
          Contact Us
        </Link>
      </motion.button>
    </motion.div>
  );
}
