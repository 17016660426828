import React from "react";
import support from "./img/support.svg";
import scalable from "./img/scalable.svg";

import { motion } from "framer-motion";
// import FadeInAnimation from "../../layout/FadeInAnimation";
import FadeInAnimationMultiple from "../../layout/FadeInAnimationMultiple";

export default function HeaderCard(props) {
  const contents = [
    {
      src: props.img,
    },
    {
      src: scalable,
    },
    {
      src: support,
    },
  ];

  return (
    <div className="flex items-center  justify-center">
      <div className=" lg:m-9 flex flex-col gap-10 lg:flex-row h-auto  justify-center items-center ">
        {contents.map((content, index) => {
          return (
            <motion.div
              variants={FadeInAnimationMultiple}
              initial="initial"
              whileInView="animate"
              viewport={{
                once: true,
              }}
              custom={index}
            >
              <img
                className="  lg:w-[25rem] w-[18.75rem] p-3 lg:p-3"
                src={content.src}
                alt="/"
              />
            </motion.div>
          );
        })}
      </div>
    </div>
  );
}
