const FadeInAnimationMultiple = {
    initial: {
        opacity: 0,
        y: 30,
    },
    animate: (index) => ({
        opacity: 1,
        y: 0,
        transition: {
            delay: 0.15 * index,
            duration: 0.35,
        }
    }),
};

export default FadeInAnimationMultiple;