import React from "react";
import vector from "../../assets/CareerPage/Vector.svg";

export default function ContentForCareer({ heading, ArrayForContent }) {
  return (
    <>
      <div>
        <h1 className=" text-[#9F0C30] text-[20px] font-[600] pl-2  mt-6 lg:mt-5">
          {heading}
        </h1>
      </div>
      {ArrayForContent.map((item, index) => {
        return (
          <div
            className=" flex justify-normal items-center pl-2  lg:pl-4 py-2 "
            key={index}
          >
            <img className="w-3 h-3 lg:w-2 lg:h-2" src={vector} alt="" />

            <div className=" ml-2 text-[16px] lg:text-[17px] text-gray-800">
              {item}
            </div>
          </div>
        );
      })}
    </>
  );
}
