import React from 'react'
import ContentForCareer from '../ContentForCareer';
import StipendStructure from '../StipendStructure';

export default function ReactNativeJob() {
    // Responsibilities
const responsibilities = [
    "Build pixel-perfect, buttery smooth UIs across both mobile platforms.",
    "Leverage native APIs for deep integrations with both platforms.",
    "Diagnose and fix bugs and performance bottlenecks for performance that feels native.",
    "Reach out to the open source community to encourage and help implement mission-critical software fixes—React Native moves fast and often breaks things.",
    "Maintain code and write automated tests to ensure the product is of the highest quality.",
    "Transition existing React web apps to React Native."
  ];
  
  // Requirements
  const requirements = [
    "Pursuing or completed a degree in Computer Science, Software Engineering, or a related field.",
    "Strong proficiency in JavaScript and ES6+ syntax.",
    "Familiarity with React and React Native frameworks.",
    "Knowledge of mobile app development principles and practices.",
    "Experience with version control systems, such as Git.",
    "Understanding of RESTful APIs and asynchronous programming.",
    "Ability to write clean, well-documented code.",
    "Problem-solving skills and a proactive attitude towards learning.",
    "Effective communication and collaboration abilities within a team."
  ];
  
  // Additional Requirements
  const additionalRequirements = [
    "Prior experience with mobile app development or React Native projects.",
    "Familiarity with UI/UX design principles.",
    "Knowledge of state management libraries like Redux.",
    "Familiarity with back-end technologies and databases.",
    "Experience with testing frameworks such as Jest or Detox.",
    "Understanding of app deployment processes for iOS and Android platforms.",
    "Proficiency in other programming languages.",
    "Participation in hackathons, coding competitions, or open-source contributions.",
    "Any specific mobile app projects or portfolio showcasing relevant work."
  ];
  
  const skills = [
    "Firm grasp of the JavaScript language and its nuances, including ES6+ syntax",
    "Knowledge of functional or object-oriented programming",
    "Ability to write well-documented, clean JavaScript code",
    "Rock solid at working with third-party dependencies and debugging dependency conflicts",
    "Familiarity with native build tools, like XCode, Gradle, Android Studio, IntelliJ.",
    "Understanding of REST APIs, the document request model, and offline storage",
    "Experience with automated testing suites, like Jest or Mocha",
    "Make sure to mention any other frameworks, libraries, or other technologies relevant to your project",
    "List any education level or certification you may require"
  ];
  
  return (

     <>
    <ContentForCareer
      heading={"Responsibilities"}
      ArrayForContent={responsibilities}
    />
    <ContentForCareer
      heading={"Requirement"}
      ArrayForContent={requirements}
    />
    <ContentForCareer
      heading={"Additional Requirements"}
      ArrayForContent={ additionalRequirements}
    />
    <ContentForCareer
      heading={"Skills"}
      ArrayForContent={ skills}
    />
    <StipendStructure />
   
   </>
  )
}
