// import React from "react";
// import "./featues.css";
// import Flipcard from "../../components/Product&Service/Flipcard";
// import frontimg1 from "../../components/Product&Service/img/for management.svg";
// import frontimg2 from "../../components/Product&Service/img/for management (1).svg";
// import frontimg3 from "../../components/Product&Service/img/for management (2).svg";
// import backimg1 from "../../components/Product&Service/img/for management (3).svg";
// import backimg2 from "../../components/Product&Service/img/for management (4).svg";
// import backimg3 from "../../components/Product&Service/img/for management (5).svg";
// import OtherHeading from "../../components/Product&Service/OtherHeading";
// import Placement from "../../components/Product&Service/PlacementCards";
// import LayoutPlacementPage from "../../components/Product&Service/LayoutPlacementPage";
// import { motion } from "framer-motion";
// import FadeInAnimation from "../../layout/FadeInAnimation";
// import FadeInAnimationMultiple from "../../layout/FadeInAnimationMultiple";

// import FinalAnimation from "../../components/Product&Service/Animationsbanner/FinalAnimation";

// export default function PlacementManagement() {
//   const contents = [
//     {
//       frontimg: frontimg1,
//       backimg: backimg1,
//     },
//     {
//       frontimg: frontimg2,
//       backimg: backimg2,
//     },
//     {
//       frontimg: frontimg3,
//       backimg: backimg3,
//     },
//   ];

//   return (
//     <>
//       <LayoutPlacementPage
//         headingBlack="Your"
//         headingRed="Career"
//         headingBlack2="Begins with "
//         headingRed2="us"
//         para="Revolutionize placement processes with our seamless management system, optimizing job postings, applications, interviews, and student placements"
//         Img={<FinalAnimation animationOf={"pms"} />}
//         children={
//           <div className="w-">
//             <div className="full bg-gradient-to-tr from-[#ec568d2a] py-[15px] pb-8  to-[#3110b531] h-full w-full">
//               <h1 className="text-[#131B29] text-center   lg:pl-[55px] mb-[15px] lg:mb-[45px] lg:my-[35px] md:text-base text-[1.9rem] md:text-[2rem] lg:text-[2.8rem] font-bold lg:font-semibold ">
//                 Aspects of our <span className="text-[#3110B5]">Creation</span>
//               </h1>
//               <div className="flex flex-wrap justify-around w-full gap-4 justify-items-center md:gap-4 lg:gap-6 ">
//                 {contents.map((content, index) => {
//                   return (
//                     <motion.div
//                       variants={FadeInAnimationMultiple}
//                       initial="initial"
//                       whileInView="animate"
//                       viewport={{
//                         once: true,
//                       }}
//                       custom={index}
//                     >
//                       <Flipcard
//                         key={index}
//                         frontimg={content.frontimg}
//                         backimg={content.backimg}
//                       />
//                     </motion.div>
//                   );
//                 })}
//               </div>
//             </div>
//             <motion.div
//               className="p-4 sm:p-6 md:p-8 lg:p-10"
//               variants={FadeInAnimation}
//               initial="initial"
//               whileInView="animate"
//               viewport={{
//                 once: true,
//               }}
//             >
//               <OtherHeading
//                 heading="Explore More Features"
//                 para="Easy-to-use, User Friendly, Customisable Modules, Data Encryption and Security, Auto Backup and much more"
//               />
//             </motion.div>
//             <div className="mb-8 ">
//               <Placement />
//             </div>
//           </div>
//         }
//       />
//     </>
//   );
// }
import React from "react";
import "./featues.css";
import Flipcard from "../../components/Product&Service/Flipcard";
import frontimg1 from "../../components/Product&Service/img/for management.svg";
import frontimg2 from "../../components/Product&Service/img/for management (1).svg";
import frontimg3 from "../../components/Product&Service/img/for management (2).svg";
import backimg1 from "../../components/Product&Service/img/for management (3).svg";
import backimg2 from "../../components/Product&Service/img/for management (4).svg";
import backimg3 from "../../components/Product&Service/img/for management (5).svg";
import OtherHeading from "../../components/Product&Service/OtherHeading";
import Placement from "../../components/Product&Service/PlacementCards";
import LayoutPlacementPage from "../../components/Product&Service/LayoutPlacementPage";
 import { motion } from "framer-motion";
import FadeInAnimation from "../../layout/FadeInAnimation";
import FadeInAnimationMultiple from "../../layout/FadeInAnimationMultiple";

import FinalAnimation from "../../components/Product&Service/Animationsbanner/FinalAnimation";

export default function PlacementManagement() {
  const contents = [
    {
      frontimg: frontimg1,
      backimg: backimg1,
    },
    {
      frontimg: frontimg2,
      backimg: backimg2,
    },
    {
      frontimg: frontimg3,
      backimg: backimg3,
    },
  ];

  return (
    <>
      <LayoutPlacementPage
        headingBlack="Your"
        headingRed="Career"
        headingBlack2="Begins with "
        headingRed2="us"
        para="Revolutionize placement processes with our seamless management system, optimizing job postings, applications, interviews, and student placements"
        Img={<FinalAnimation animationOf={"pms"} />}

        children={
          <div className="w-">
            <div className="full bg-gradient-to-tr from-[#ec568d2a] py-[15px] pb-5  to-[#3110b531] h-full w-full">
              <div className="flex flex-wrap justify-around w-full gap-4 justify-items-center md:gap-4 lg:gap-6 ">
              <h1 className="text-[#131B29] pl-2 lg:pl-[30px] font-[650] mb-[15px] lg:mb-[25px] lg:my-[35px] md:text-base text-[1.7rem] md:text-[2rem] lg:text-[2.3rem] f ">
                Aspects of our <span className="text-[#3110B5]">Creation</span>
              </h1>
              <h1 className=" hidden lg:block invisible text-[#131B29] pl-2 lg:pl-[50px] mb-[15px] lg:mb-[25px] lg:my-[35px] md:text-base text-[1.7rem] md:text-[2rem] lg:text-[2.3rem] font-semibold">
                Aspects of our <span className="text-[#3110B5]">Creation</span>
              </h1>
              <h1 className=" hidden lg:block invisible text-[#131B29] pl-2 lg:pl-[50px] mb-[15px] lg:mb-[25px] lg:my-[35px] md:text-base text-[1.7rem] md:text-[2rem] lg:text-[2.3rem] font-semibold">
                Aspects of our <span className="text-[#3110B5]">Creation</span>
              </h1>
              </div>
              <div className="flex flex-wrap justify-around w-full gap-4 justify-items-center md:gap-4 lg:gap-6 ">
                {contents.map((content, index) => {
                  return (
                    <motion.div
                      variants={FadeInAnimationMultiple}
                      initial="initial"
                      whileInView="animate"
                      viewport={{
                        once: true,
                      }}
                      custom={index}
                    >
                      <Flipcard
                        key={index}
                        frontimg={content.frontimg}
                        backimg={content.backimg}
                      />
                    </motion.div>
                  );
                })}
              </div>
            </div>
            <motion.div
              className="p-4 sm:p-6 md:p-8 lg:p-1"
              variants={FadeInAnimation}
              initial="initial"
              whileInView="animate"
              viewport={{
                once: true,
              }}
            >
              <OtherHeading
                heading="Explore More Features"
                para="Easy-to-use, User Friendly, Customisable Modules, Data Encryption and Security, Auto Backup and much more"
              />
            </motion.div>
            <div className="mb-8 ">
              <Placement />
            </div>
          </div>
        }
      />
    </>
  );
}
