import React from "react";
import vector from "../../assets/CareerPage/Vector.svg";

export default function StipendStructure() {
  return (
    <>
      {" "}
      <div>
        <h1 className=" text-[#9F0C30] text-[20px] font-[600] pl-2  mt-6 lg:mt-5">
          Internship Details{" "}
        </h1>
      </div>
      <div className=" flex flex-col">
        <div className=" flex justify-normal items-center pl-2  lg:pl-4 py-2 ">
          <img className="w-3 h-3 lg:w-2 lg:h-2" src={vector} alt="" />

          <div className=" ml-2 text-gray-800">
            <span className=""> Internship Duration:</span> 12 months
          </div>
        </div>

        <div className=" flex justify-normal items-center pl-2  lg:pl-4 py-2 ">
          <img className="w-3 h-3 lg:w-2 lg:h-2" src={vector} alt="" />
          <div className=" ml-2 text-gray-800">
            <span className=""> Stipend Structure:</span>
          </div>
        </div>
        <div className=" pl-10  lg:pl-10">
          <div className=" flex items-center">
            <div className=" w-[5px] h-[5px] bg-black rounded-full"></div>
            <div className=" ml-[8px] text-gray-800">
            Rs. 5,000 for first 6 months of Internship
            </div>
          </div>
          <div className=" flex items-center">
            <div className=" w-[5px] h-[5px] bg-black rounded-full"></div>

            <div className=" ml-[8px] text-gray-800">
            Rs. 8,000 from 7th Month to 9th Month of Internship
            </div>
          </div>
          <div className=" flex items-center">
            <div className=" w-[5px] h-[5px] bg-black rounded-full"></div>

            <div className=" ml-[8px] text-gray-800">
            Rs. 10,000 from 10th Month to 12th Month of Internship
            </div>
          </div>
        </div>

        <div className=" flex justify-normal items-center pl-2  lg:pl-4 py-2 ">
          <img className="w-3 h-3 lg:w-2 lg:h-2" src={vector} alt="" />

          <div className=" ml-2 text-gray-800">
            
            Potential for Full-Time: Your performance will determine your eligibility for a full-time role.
          </div>
        </div>

        <div className=" flex justify-normal items-center pl-2  lg:pl-4 py-2 ">
          <img className="w-3 h-3 lg:w-2 lg:h-2" src={vector} alt="" />

          <div className=" ml-2 text-gray-800">
            <span className=""> Internship Mode: </span>
            The internship will be conducted online or remotely from home.
          </div>
        </div>
      </div>
    </>
  );
}
