import React from 'react'
import CareerPageLayout from '../../layout/CareerPageLayout'
import ReactNativeJob from '../../components/Career/JobPageContent/ReactNativeJob'

export default function ReactNative() {
  return (
    <>
    <CareerPageLayout
    PageHeading={"React Native Developer -Internship"}
    jobQuote="Where Apps come to Life: The Innovation Playground of a React Native Developer"
    >
        <ReactNativeJob/>
    </CareerPageLayout>
    </>
  )
}
