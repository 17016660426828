// Backdrop.jsx
import { motion } from "framer-motion";

const Backdrop = ({ children, onClick }) => {
  const backdropStyle = {
    position: 'fixed',
    top: "0%",
    left: 0,
    width: '100%',
    height: '100%',
    margin: "auto",
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000,
  };

  return (
    <motion.div
      onClick={onClick}
      style={backdropStyle}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
};

export default Backdrop;
