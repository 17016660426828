import React from "react";
import shedule from "./img/meal 1.svg";
import attendance from "./img/attendance 2.svg";
import timemoney from "./img/time-is-money 3.svg";
import message from "./img/digital 1.svg";
import feedback from "./img/feedback 5.svg";
import library from "./img/delivery-truck 1.svg";
import FeatureCard from "./FeatureCard";

const HostelData = [
  {
    title: "Food Management",
    content:
      "Our college management system optimizes event planning, coordination, and communication, ensuring efficient resource allocation and improving the college experience by streamlining events and activities.",
    image: shedule,
  },
  {
    title: "Attendance Management",
    content:
      "In a college management system, attendance management entails methodically tracking and recording students' class presence or absence. It's vital for engagement monitoring, policy adherence, and precise administrative records.",
    image: attendance,
  },
  {
    title: "Time Saving",
    content:
      "Our Hostel management system streamlines operations, minimizes manual work, and offers a digital platform, enhancing efficiency for staff and students, resulting in increased productivity and a smoother learning environment.",
    image: timemoney,
  },
  {
    title: "Information Sharing ",
    content:
      "Our Hostel Management System facilitates the safe and efficient sharing of important data, updates, and communications among students, faculty, staff, and administrators, prioritizing accessibility and data security.",
    image: message,
  },
  {
    title: "Feedback Management",
    content:
      "In our hostel management system, feedback management encompasses collecting, analyzing, and utilizing input from students, faculty, and stakeholders to improve education quality, administration, and services.",
    image: feedback,
  },
  {
    title: "Transport Management",
    content:
      "Transport management in the hostel management system refers to the coordination and monitoring of transportation services, such as shuttle buses or pick-up schedules, to facilitate student mobility.",
    image: library,
  },
];
export default function HostelCard() {
  return <FeatureCard arr={HostelData} height=" lg:h-[25rem] h-[22rem]  " />;
}
