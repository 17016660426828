// import React from "react";
// import { motion } from "framer-motion";
// import "./style.css";

// const FlipCard = (props) => {
//   return (
//     <div className="lg:w-[25rem]  lg:h-[31.25rem] w-[23rem]  h-[30rem] ">
//       <motion.div
//         className="card"
//         whileHover={{ rotateY: 180 }}
// onFocus={{ rotateY: 180 }}
//         transition={{ duration: 1, ease: "easeInOut" }}
//       >
//         <div className="card-front">
//           <img
//             src={props.frontimg}
//             alt="front img card"
//             className="w-full h-full"
//           />
//         </div>
//         <div className="card-back">
//           <img
//             src={props.backimg}
//             alt="back img card"
//             className="w-[full] h-full "
//           />
//         </div>
//       </motion.div>
//     </div>
//   );
// };

// export default FlipCard;
import React, { useState } from "react";
import { motion } from "framer-motion";
import "./style.css";

const FlipCard = (props) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const toggleFlip = () => {
    setIsFlipped(!isFlipped);
  };
  const toggleFlipTrue = () => {
    setIsFlipped(true);
    setTimeout(() => {
      setIsFlipped(false);
    },2500);
  };
 

  return (
    <>
      <div className="lg:w-[25rem] hidden lg:block  lg:h-[31.25rem] w-[23rem]  h-[30rem] ">
        <motion.div
          className="card " 
          transition={{ duration: 0.5, ease: "easeInOut" }}
          onMouseEnter={toggleFlipTrue}
         
          initial={{ rotateY: 0 }}
          animate={{ rotateY: isFlipped ? 180 : 0 }}
    
        >
          <div className="card-front">
            <img
              src={props.frontimg}
              alt="front img card"
              className="w-full h-full"
            />
          </div>
          <div className="card-back">
            <img
              src={props.backimg}
              alt="back img card"
              className="w-[full] h-full "
            />
          </div>
        </motion.div>
      </div>
      <div className="lg:w-[25rem] block lg:hidden  lg:h-[31.25rem] w-[23rem]  h-[30rem] ">
        <motion.div
          className="card"
          onClick={toggleFlip}
          initial={{ rotateY: 0 }}
          animate={{ rotateY: isFlipped ? 180 : 0 }}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          <div className="card-front">
            <img
              src={props.frontimg}
              alt="front img card"
              className="w-full h-full"
            />
          </div>
          <div className="card-back">
            <img
              src={props.backimg}
              alt="back img card"
              className="w-[full] h-full "
            />
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default FlipCard;
