import React from "react";
import OtherServiceCard from "./OtherServiceCard";

const ServiceData = [
  {
    title: "Best Design",
    content:
      "We are providing the unique and best design. Each and every design is unique and it is customized by user preference.",
  },
  {
    title: "Efficient",
    content:
      "We carefully choose appropriate tech services to minimize development efforts, timelines, and costs.",
  },
  {
    title: "Fast",
    content:
      "We encourage our developers to upgrade their skills for faster problem-solving and the creation of efficient solutions.",
  },
];

export default function OtherService() {
  return <OtherServiceCard arr={ServiceData} />;
}
