import "./Animation.css";
import card1 from "../../assets/CareerPage/AnimationCard2.svg";
import card2 from "../../assets/CareerPage/AnimationCard6.svg";
import card3 from "../../assets/CareerPage/AnimationCard3.svg";
import card4 from "../../assets/CareerPage/AnimationCard4.svg";
import card5 from "../../assets/CareerPage/AnimationCard5.svg";
import { motion, AnimatePresence } from "framer-motion";
import FadeInAnimation from "../../layout/FadeInAnimation";
import { useState, useEffect } from "react";

export default function AnimationCareerPage() {

  const cards = [card1, card2, card3, card4, card5];
  const [hiddenFirst, setHiddenFirst] = useState("flex");
  const [hiddenSecond, setHiddenSecond] = useState("hidden");

  const handleAnimationCard = () => {
    hiddenFirst === "hidden" ? setHiddenFirst("flex") : setHiddenFirst("hidden");
    hiddenSecond === "flex" ? setHiddenSecond("hidden") : setHiddenSecond("flex");
  }

  useEffect(() => {
    const intervalId = setInterval(handleAnimationCard, 9000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenFirst, hiddenSecond]);

  return (
    <div className="flex flex-col gap-6 md:gap-16 my-8">
      <motion.h1
        className="text-center md:text-[1.25rem] lg:text-[2.1875rem] text-[#9f0c30] font-semibold"
        variants={FadeInAnimation}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
      >
        Reasons to become a part of TechHive?
      </motion.h1>
      <AnimatePresence>
        <div className={`${hiddenFirst} relative w-[50%] md:w-[80%] h-[15rem] md:h-[25rem] justify-center items-center`}>
          <img
            className="absolute animate2 h-full"
            src={cards[0]}
            alt=""
          />

          <img
            className="absolute animate1 h-full"
            src={cards[1]}
            alt=""
          />

          <img
            className="absolute animate3 h-full"
            src={cards[2]}
            alt=""
          />
        </div>
        <div className={`${hiddenSecond} relative w-[50%] md:w-[80%] h-[15rem] md:h-[25rem] justify-center items-center`}>
          <img
            className="absolute animate2 h-full"
            src={cards[3]}
            alt=""
          />

          <img
            className="absolute animate1 h-full"
            src={cards[0]}
            alt=""
          />

          <img
            className="absolute animate3 h-full"
            src={cards[4]}
            alt=""
          />
        </div>
      </AnimatePresence>
    </div>
  );
}
