import React, { useEffect } from "react";
import { motion } from "framer-motion";
import girlImg from "../../components/Product&Service/img/girlImage.svg";
import { Link } from "react-router-dom";

export default function BannerLayout({
  headingBlack,
  headingRed,
  headingBlack2,
  headingRed2,
  para1,
  para2,
  para3,
  children,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="w-full   h-auto lg:h-[34.375rem]  flex flex-col justify-around items-center bg-[#2a7ab428]">
        <div className="w-full h-full flex   ">
          <img className=" h-full lg:block hidden" src={girlImg} alt="Aimage" />
          <div className="flex flex-col gap-4 pl-5 lg:pl-[17.5rem] py-[0.125rem] mt-5   lg:mt-10 md:gap-6    md:h-3/4">
            <div className="font-bold text-[2.2rem] md:text-[4.3rem] md:w-full md:leading-snug">
              <h1>
                {headingBlack}
                <span className="text-[#EC568E] pl-1"> {headingRed}</span>
              </h1>
              <h1>
                {headingBlack2}
                <span className="text-[#EC568E]"> {headingRed2}</span>
              </h1>
            </div>
            <div className="text-gray-500 text-sm lg:block hidden  md:text-2xl py-1 font-medium">
              <p> {para1}</p>
              <p> {para2}</p>
              <p> {para3}</p>
            </div>
            <div className="text-gray-500  lg:hidden block  pr-5 md:text-2xl  font-medium">
              <p> {para1}
                {para2}
                {para3}</p>
            </div>
            <div className=" flex justify-end mb-7  mt-1 pr-5 items-center w-full">
              <div className="flex justify-between gap-4 md:gap-8 items-center w-fit">
                <button
                >
                  <a
                    href="#services"
                    className="text-[#EC568E] hover:text-[#98365a] border-[#EC568E] hover:border-[#98365a] transition-all border px-4 md:px-6 py-2 rounded-md font-medium"
                  >
                    View Services
                  </a>
                </button>{" "}
                <button
                >
                  <Link
                    to="/reachus"
                    className="px-4 md:px-6 py-2 rounded-md hover:bg-[#dd4d81] transition-all  bg-[#EC568E] text-white"
                  >
                    Contact Us
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="items-center lg:flex hidden  -mt-32  flex-col w-full gap-9 justify-center ">
        {children}
      </div>

      <div className=" lg:hidden  w-full  ">
        {children}
      </div>


    </>
  );
}
