import React from "react";
import vector from "../../assets/CareerPage/Vector.svg";

export default function TechnicalRequirements({ PrimarySkills }) {
  return (
    <>
      <div>
        <h1 className=" text-[#9F0C30] text-[20px] font-[600] pl-2  mt-6 lg:mt-5">
          Technical Requirements
        </h1>
      </div>

      <div className=" flex justify-normal items-center pl-2  lg:pl-4 py-2 ">
        <img className="w-3 h-3 lg:w-2 lg:h-2" src={vector} alt="" />

        <div className=" ml-2 text-gray-800">Primary Skilld</div>
      </div>

      <div className=" pl-10  lg:pl-10">
        {PrimarySkills.map((item, index) => {
          return (
            <div key={index} className=" flex items-center">
              <div className=" w-[5px] h-[5px] bg-black rounded-full"></div>
              <div className=" ml-[8px] text-gray-800">{item}</div>
            </div>
          );
        })}
      </div>
    </>
  );
}
