import React from "react";
import FeatureCard from "./FeatureCard";
import project from "./img/business-report 1.svg";
import timeismoney from "./img/time-is-money 3.svg";
import route from "./img/destination (2) 1.svg";
import carwash from "./img/insurance 1.svg";
import skill from "./img/conversation 1.svg";
import feedback from "./img/feedback 5.svg";

const CabData = [
  {
    title: "Report Management",
    content:
      "Our cab management prioritizes safety through vehicle maintenance, traffic rule compliance, driver training, and background checks. Real-time tracking and communication tools ensure comfortable, secure travel.",
    image: project,
  },
  {
    title: "Time Saving",
    content:
      "Our cab management prioritizes safety with vehicle maintenance, traffic compliance, trained drivers, and checks. Real-time tracking and emergency features ensure secure, comfortable travel.",
    image: timeismoney,
  },
  {
    title: "Navigation",
    content:
      "Our Cab management employs GPS for precise route planning, real-time updates, and turn-by-turn guidance, ensuring efficient transportation, timely pickups, and enhanced passenger satisfaction.",
    image: route,
  },
  {
    title: "Trip Satisfaction",
    content:
      "In our cab management system, trip satisfaction embodies passenger contentment, including safety, comfort, punctuality, cleanliness, and service quality, ensuring positive ride experiences.",
    image: carwash,
  },
  {
    title: "Communication",
    content:
      "Efficient communication is essential in a cab management system, ensuring smooth, reliable transportation. Passengers travel confidently and conveniently with our emphasis on effective communication.",
    image: skill,
  },
  {
    title: "Feedback Management",
    content:
      "Efficient cab management relies on vital communication. We prioritize it to deliver a smooth, reliable transportation service, ensuring passengers travel with confidence and convenience.",
    image: feedback,
  },
];

export default function CabManage() {
  return <FeatureCard arr={CabData} height="  lg:h-[25rem] h-[22rem] " />;
}
