import React from "react";
import arrow from "../ReachUs/img/Arrow 3.svg";

export default function ContactCards(props) {
  return (
    <>
      <div className="flex flex-col justify-between relative mt-[5rem]  w-[22.5rem] h-[16.25rem] border-2 rounded-[0.5rem] border-[#EAECF0] shadow-lg ">
        <div className=" flex absolute bottom-[13rem] right-[8.5rem] lg:bottom-[12.1875rem] lg:right-[8rem]">
          <img
            src={props.icon}
            alt="icon"
            className="w-[5rem] h-[5rem] lg:w-[6.25rem] lg:h-[6.25rem] bg-white p-4 border-2 rounded-full border-[#EAECF0] shadow-lg"
          />
        </div>
        <div className="flex justify-center items-center h-full mt-[1.5625rem]">
          <p className=" text-center p-[3.125rem]   font-[31.25rem] text-[1rem] font-inter">
            {props.para}
          </p>
        </div>

        <div className="flex group justify-center  justify-items-center bg-[#fbdde8] hover:bg-[#e98eae]  transition-all rounded-b-md ">
          <button className="mt-2 text-[#1849a9] text-[1.5625rem] font-bold self-center mb-1 font-poppins">
            <div className="flex justify-center  justify-items-center  ">
              <a
                href={props.link}
                className="text-[25px] font-bold self-center  mb-1 font-poppins"
              >
                {props.btntitle}
              </a>

              <div className="flex relative ml-6">
                <img src={arrow} alt="arrow" className="w-4 h-4 self-center" />
                <img
                  src={arrow}
                  alt="arrow"
                  className="absolute right-[0.625rem] w-4 h-4 self-center"
                />
              </div>
            </div>
          </button>
        </div>
      </div>
    </>
  );
}
