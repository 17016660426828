import React from "react";
import HeaderCard from "../../components/Product&Service/HeaderCard";
import OtherHeading from "../../components/Product&Service/OtherHeading";
import OtherService from "../../components/Product&Service/OtherService";
import LogoContent from "../../components/Product&Service/LogoContent";
import ContactUs from "../../components/Product&Service/ContactUs";
import premium from "../../components/Product&Service/img/1.svg";

import BannerLayout from "../../components/Product&Service/BannerLayoutApp";

export default function AppPg() {
  return (
    <>
      <BannerLayout
        headingBlack="Turning"
        headingRed="Ideas"
        headingBlack2="Seamless"
        headingRed2="Apps"
        para1="We create customized apps to match your needs, handling"
        para2="everything from design and development to launch and upkeep."
        para3="Collaborate, Learn, and Thrive together."
        children={
          <>
            <HeaderCard img={premium} />
          </>
        }
      />
      <OtherHeading
        heading="Other Services"
        para="Experience the ultimate website with unparalleled service. We pride
      ourselves on delivering the finest tech."
      />
      <OtherService />
      <LogoContent />
      <ContactUs />

      {/* <div className='w-full  h-auto lg:h-[550px] relative flex justify-around items-center bg-[#2a7ab428]'>
      <div className='w-full h-full flex   '>
        <img  className=' h-full lg:block hidden' src={girlImg} alt="Aimage" />
        <div className="flex flex-col gap-4 lg:pl-[280px] py-[2px] mt-10  lg:mt-10 md:gap-6 px-8 md:px-16  md:h-3/4">
          <div className="font-bold text-[1.6rem] md:text-[4.3rem] md:w-3/4 md:leading-snug">
            <h1>
            Turning <span className="text-[#EC568E]">Ideas</span>{" "}
            
            </h1>
            <h1>
            Seamless <span className="text-[#EC568E]">Apps</span>{" "}
           
            </h1>
          </div>
          <div className="text-gray-500 md:text-2xl font-medium">
            <p>We create customized apps to match your needs, handling  </p>
            <p>    everything from design and development to launch and upkeep.</p>
            <p>Collaborate, Learn, and Thrive together.</p>
          </div>
          <div className=" flex justify-end mb-4 items-center w-full">

          <div className="flex justify-between gap-4 md:gap-8 items-center w-fit">
         
            <Link to="/aboutus" className="text-[#EC568E] border-[#EC568E] border px-4 md:px-6 py-2 rounded-md font-medium">
            View Services
            </Link>   <Link
              to="/reachus"
              className="px-4 md:px-6 py-2 rounded-md bg-[#EC568E] text-white"
            >
             Contact Us
            </Link>
          </div>
          </div>
        </div>
      </div>
    <div className="items-center  absolute top-[380px]  lg:top-[420px] flex-col w-full gap-9 justify-center flex">
      <HeaderCard img={premium} />
      <OtherHeading
        heading="Other Services"
        para="Experience the ultimate website with unparalleled service. We pride
      ourselves on delivering the finest tech."
      />
      <OtherService />
      <LogoContent />
      <ContactUs />
    </div>
    </div> 
    <div className="items-center  invisible    flex-col w-full gap-9 justify-center flex">
      <HeaderCard img={premium} />
      <OtherHeading
        heading="Other Services"
        para="Experience the ultimate website with unparalleled service. We pride
      ourselves on delivering the finest tech."
      />
      <OtherService />
      <LogoContent />
      <ContactUs />
    </div> */}
    </>
  );
}
