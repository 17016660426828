import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import logo from "../header_logo.svg";

import placement from "../icons/product-placement.svg";
import taxi from "../icons/taxi.svg";
import hostel from "../icons/hostel.svg";
import college from "../icons/layer.svg";
import mobile from "../icons/app-development.svg";
import website from "../icons/improvement.svg";
import HeaderNavbarChild from "../components/Layout/HeaderNavbarChild";

import { motion, AnimatePresence } from "framer-motion";

export default function Header() {
  const path = useLocation();
  const currentPath = path.pathname;

  const navLinkStyle =
    "font-semibold hover:text-header-text-blue transition duration-150";
  const navActiveStyle = "!text-header-text-blue";
  const smallNavActiveLinkStyle = "!text-[#EC568E]";
  const smallNavBorder = <div className="h-[1px] w-full bg-gray-400"></div>;

  const [homeActiveStyle, setHomeActiveStyle] = useState("");
  const [aboutUsActiveStyle, setAboutUsActiveStyle] = useState("");
  const [productsServicesActiveStyle, setProductsServicesActiveStyle] =
    useState("");
  const [careerActiveStyle, setCareerActiveStyle] = useState("");
  const [reachUsActiveStyle, setReachUsActiveStyle] = useState("");

  const [placementActiveStyle, setPlacementActiveStyle] = useState("");
  const [cabActiveStyle, setCabActiveStyle] = useState("");
  const [hostelActiveStyle, setHostelActiveStyle] = useState("");
  const [collegeActiveStyle, setCollegeActiveStyle] = useState("");
  const [mobileActiveStyle, setMobileActiveStyle] = useState("");
  const [webActiveStyle, setWebActiveStyle] = useState("");

  const [viewNav, setViewNav] = useState(false);

  useEffect(() => {
    currentPath === "/" ? setHomeActiveStyle(true) : setHomeActiveStyle(false);
    currentPath === "/aboutus"
      ? setAboutUsActiveStyle(true)
      : setAboutUsActiveStyle(false);
    currentPath === "/placementmanagement" ||
    currentPath === "/cabmanagement" ||
    currentPath === "/hostelmanagement" ||
    currentPath === "/collegemanagement" ||
    currentPath === "/websitepage" ||
    currentPath === "/apppage"
      ? setProductsServicesActiveStyle(true)
      : setProductsServicesActiveStyle(false);
    currentPath === "/career"
      ? setCareerActiveStyle(true)
      : setCareerActiveStyle(false);
    currentPath === "/reachus"
      ? setReachUsActiveStyle(true)
      : setReachUsActiveStyle(false);

    currentPath === "/placementmanagement"
      ? setPlacementActiveStyle(true)
      : setPlacementActiveStyle(false);
    currentPath === "/cabmanagement"
      ? setCabActiveStyle(true)
      : setCabActiveStyle(false);
    currentPath === "/hostelmanagement"
      ? setHostelActiveStyle(true)
      : setHostelActiveStyle(false);
    currentPath === "/collegemanagement"
      ? setCollegeActiveStyle(true)
      : setCollegeActiveStyle(false);
    currentPath === "/apppage"
      ? setMobileActiveStyle(true)
      : setMobileActiveStyle(false);
    currentPath === "/websitepage"
      ? setWebActiveStyle(true)
      : setWebActiveStyle(false);
  }, [currentPath]);

  const [showServices, setShowServices] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const hideTimeoutRef = useRef(null);

  const handleShow = () => {
    clearTimeout(hideTimeoutRef.current);
    setShowServices(true);
  };

  const handleHide = () => {
    hideTimeoutRef.current = setTimeout(() => {
      setShowServices(false);
    }, 400);
  };

  const handleDropDown = () => {
    showDropDown === false ? setShowDropDown(true) : setShowDropDown(false);
  };

  const handleViewNav = () => {
    if (viewNav === false) {
      setViewNav(true);
      setShowDropDown(false);
    } else {
      setViewNav(false);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(hideTimeoutRef.current);
    };
  }, []);

  const navVisibleRef = useRef();
  const subMenuRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        navVisibleRef.current &&
        !navVisibleRef.current.contains(event.target) &&
        !subMenuRef.current?.contains(event.target)
      ) {
        setViewNav(false);
        setShowDropDown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const items = [
    {
      link: "/placementmanagement",
      img: placement,
      title: "Placement Management System",
      desc: "Streamline Placements with Our Placement Management System.",
      activeStyle: placementActiveStyle,
    },
    {
      link: "/cabmanagement",
      img: taxi,
      title: "Cab Management System",
      desc: "Elevate Commuting Experience: Our Cab Management System.",
      activeStyle: cabActiveStyle,
    },
    {
      link: "/hostelmanagement",
      img: hostel,
      title: "Hostel Management System",
      desc: "Enhancing Hostel Life with Our Innovative Management System.",
      activeStyle: hostelActiveStyle,
    },

    {
      link: "/collegemanagement",
      img: college,
      title: "College Management System",
      desc: "Navigating Academic Brilliance with Our College Management System.",
      activeStyle: collegeActiveStyle,
    },
    {
      link: "apppage",
      img: mobile,
      title: "Mobile Application",
      desc: "Experience an App that's both Efficient and Dependable with Our Service.",
      activeStyle: mobileActiveStyle,
    },
    {
      link: "websitepage",
      img: website,
      title: "Website",
      desc: "Crafting Your Online Identity: Explore Our Website Services.",
      activeStyle: webActiveStyle,
    },
  ];

  return (
    <div className="flex justify-between fixed z-50  items-center px-6 bg-header-bg-black text-white font-poppins w-full">
      <div>
        <Link to="/">
          <img src={logo} alt="logo" className="h-14 md:h-[65px] py-2" />
        </Link>
      </div>
      <div className="f">
        <ul className="hidden md:flex gap-16 text-lg">
          <li>
            <Link
              to="/"
              className={`${navLinkStyle} ${homeActiveStyle && navActiveStyle}`}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/aboutus"
              className={`${navLinkStyle} ${
                aboutUsActiveStyle && navActiveStyle
              }`}
            >
              About Us
            </Link>
          </li>
          <li className="flex items-center gap-1 group">
            <div
              className={`${navLinkStyle} ${
                productsServicesActiveStyle && navActiveStyle
              } flex items-center gap-1 group-hover:text-header-text-blue`}
              onMouseEnter={handleShow}
              onMouseLeave={handleHide}
            >
              Products & Services
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                className={`bi bi-chevron-down transition-all duration-150 ${
                  showServices && "rotate-180"
                }`}
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </div>
          </li>
          {showServices && (
            <motion.div
              onMouseEnter={handleShow}
              onMouseLeave={handleHide}
              className="absolute left-0 z-10 w-full rounded-sm top-[65px]"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                ease: "anticipate",
                duration: 0.3,
              }}
            >
              <ul className="flex flex-wrap justify-around items-center px-4 py-8 bg-white text-black shadow-md">
                {items.map((item, index) => (
                  <li
                    onClick={() => {
                      setShowServices(false);
                    }}
                    key={index}
                    className={`w-1/3 flex items-center pb-8 ${
                      item.activeStyle && smallNavActiveLinkStyle
                    }`}
                  >
                    <HeaderNavbarChild
                      link={item.link}
                      img={item.img}
                      title={item.title}
                      desc={item.desc}
                    />
                  </li>
                ))}
              </ul>
            </motion.div>
          )}
          <li>
            <Link
              to="/career"
              className={`${navLinkStyle} ${
                careerActiveStyle && navActiveStyle
              }`}
            >
              Career
            </Link>
          </li>
          <li>
            <Link
              to="/reachus"
              className={`${navLinkStyle} ${
                reachUsActiveStyle && navActiveStyle
              }`}
            >
              Reach Us
            </Link>
          </li>
        </ul>
        <button
          className="md:hidden cursor-pointer space-y-[6px]"
          ref={navVisibleRef}
          onClick={handleViewNav}
        >
          <div
            className={`w-6 h-0.5 bg-white ${
              viewNav && "rotate-45"
            } transition-all duration-200`}
          ></div>
          <div
            className={`w-4 h-0.5 bg-white ml-auto ${viewNav && "hidden"}`}
          ></div>
          <div
            className={`w-6 h-0.5 bg-white ${
              viewNav && "-rotate-45 -translate-y-2"
            } transition-all duration-200`}
          ></div>
        </button>
      </div>
      <AnimatePresence>
        {viewNav && (
          <div className=" fixed  mt-2 py-2    pb-12 left-0 bg-white text-black z-30 shadow-md top-12 h-[98%] w-full">
            <motion.div
              className=" "
              variants={{
                open: {
                  x: "0%",
                },
                closed: {
                  x: "100%",
                },
              }}
              initial="closed"
              animate="open"
              exit="closed"
              transition={{
                ease: "easeOut",
                duration: 0.3,
              }}
            >
              <ul className="flex flex-col gap-2 h-full font-medium  text-lg px-4">
                <li
                  className={`px-4 py-2 rounded-md ${
                    homeActiveStyle && smallNavActiveLinkStyle
                  }`}
                >
                  <Link to="/" className="inline-block w-full">
                    Home
                  </Link>
                </li>
                {smallNavBorder}
                <li
                  className={`px-4 py-2 rounded-md ${
                    aboutUsActiveStyle && smallNavActiveLinkStyle
                  }`}
                >
                  <Link to="/aboutus" className="inline-block w-full">
                    About Us
                  </Link>
                </li>
                {smallNavBorder}
                <li className="">
                  <div className="flex items-center justify-between">
                    <button
                      onClick={handleDropDown}
                      ref={subMenuRef}
                      className={`group flex justify-between z-50 items-center cursor-pointer w-full py-2 px-4 ${
                        productsServicesActiveStyle && smallNavActiveLinkStyle
                      }`}
                    >
                      Products & Services
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className={`bi bi-chevron-down cursor-pointer ${
                          showDropDown && "rotate-180"
                        }`}
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </button>
                  </div>
                  {/* <AnimatePresence>
                  {showDropDown && (
                    <motion.ul className="flex flex-col justify-center bg-white"
                      initial={{
                        height: 0.4,
                        opacity: 0
                      }}
                      animate={{
                        height: "auto",
                        opacity: 1
                      }}
                      exit={{
                        height: 0
                      }}
                      transition={{
                        type: "tween",
                        duration: 0.5
                      }}
                    >
                      {items.map((item, index) => {
                        return (
                          <motion.div
                            key={index}
                            className="flex flex-col gap-2"
                          >
                            {smallNavBorder}

                            <li className={`py-2 px-4 rounded-md text-base font-medium ${item.activeStyle && smallNavActiveLinkStyle}`}>
                              <Link
                                to={item.link}
                                className="flex gap-4 w-full"
                              >
                                <img src={item.img} alt="img" className="h-5" />
                                <p>{item.title}</p>
                              </Link>
                            </li>
                          </motion.div>
                        )
                      })}
                    </motion.ul>
                  )}
                </AnimatePresence> */}
                  <AnimatePresence>
                    {showDropDown && (
                      <motion.ul
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ opacity: { duration: 1 } }}
                        className="flex flex-col justify-center  z-10 bg-white"
                      >
                        {items.map((item, index) => (
                          <motion.div
                            key={index}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0, display: "none" }}
                            transition={{
                              display: {
                                duration: 0.2,
                              },
                              opacity: {
                                duration: 0.4,
                                delay: index * 0.1, // Adding delay based on index
                              },
                            }}
                            className="flex flex-col gap-2"
                          >
                            {smallNavBorder}

                            <li
                              className={`py-2 px-4 rounded-md text-base font-medium ${
                                item.activeStyle && smallNavActiveLinkStyle
                              }`}
                            >
                              <Link
                                to={item.link}
                                className="flex gap-4 w-full"
                              >
                                <img src={item.img} alt="img" className="h-5" />
                                <p>{item.title}</p>
                              </Link>
                            </li>
                          </motion.div>
                        ))}
                      </motion.ul>
                    )}
                  </AnimatePresence>
                </li>
                {smallNavBorder}
                <li
                  className={`py-2 px-4 ${
                    careerActiveStyle && smallNavActiveLinkStyle
                  }`}
                >
                  <Link to="/career" className="inline-block w-full">
                    Career
                  </Link>
                </li>
                {smallNavBorder}
                <li
                  className={`py-2 px-4 ${
                    reachUsActiveStyle && smallNavActiveLinkStyle
                  }`}
                >
                  <Link to="/reachus" className="inline-block w-full">
                    Reach Us
                  </Link>
                </li>
                {smallNavBorder}
              </ul>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
}
